import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Tab,
  Tabs,
  TextField,
  Typography,
  Tooltip,
  Stack,
} from '@mui/material';
import React, {
  // SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import DocViewer, { DocViewerRef, DocViewerRenderers, IDocument } from '@cyntler/react-doc-viewer';
import Scrollbar from 'src/components/scrollbar';
import { CloseIcon } from 'src/theme/overrides/CustomIcons';
// import Label from 'src/components/label/Label';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSettingsContext } from 'src/components/settings';
import { useSnackbar } from 'src/components/snackbar';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import Regulations1 from 'src/pages/Jobs/Regulations1';
import Regulations2 from 'src/pages/Jobs/Regulations2';
import Regulations12019 from 'src/pages/Jobs/Regulations1_2019';
import Regulations22019 from 'src/pages/Jobs/Regulations2_2019';
import axios from 'src/utils/axios';
// eslint-disable-next-line import/no-duplicates
import Iconify from 'src/components/iconify';
import Analysis from './Analysis';
import ArtificialLighting from './ArtificialLighting';
import Options from './Options';
import Regulations from './Regulations';
import Summary from './Summary';
import { Upload } from '../../components/upload';
import Cover from './Cover';
import Specifications from './Specifications';
import BasicOptions from './BasicOptions';
import './viewJobReport.css';
import Analysis2 from './Analysis2';
import Analysis2022 from './Analysis2022';

type FormValuesProps = {
  JobAssessorID: string;
  JobCode: string;
  JobNumber: string;
  JobType: string;
  JobDwelling: string;
  JobFloors: number;
  JobStatus: string;
  JobEmailSubject: string;
  JobPrelimFinal: string;
  JobRating: number;
  JobSiteAddr: string;
  JobAddrState: string;
  JobIsUrgent: boolean;
  JobPostAssessmentFlag: boolean;
  JobRequestorID: string;
  AdditionalJobCode: string;
};

interface JobRecord {
  JobRecordID: number;
  JobNumber: string;
  EmailRecordID: number;
  JobEmailMsgID: number;
  JobEmailSubject: string;
  JobFloors: number;
  JobRequestorID: number;
  JobSiteAddr: string;
  JobAddrState: string;
  JobIsUrgent: string;
  JobCode: string;
  JobType: string;
  JobPrelimFinal: string;
  JobAssessorID: number;
  JobReprocessFlag: boolean;
  JobDwelling: string;
  JobStatus: string;
  JobProcessedFlag: string;
  JobProcessedAt: string | null;
  JobCreatedAt: string;
  JobRating: number;
  JobPostAssessmentFlag: boolean;
  UserFname: string;
  UserLname: string;
  UserEmail: string;
}

interface SaveReportData {
  Rating: number;
  DPNumber: number;
  Options: any[];
  WaterSectionOptions: any[];
  EnergySectionOptions: any[];
  State: string;
  NatHersClimateZone: string;
  DwellingType: string;
  GarageSide: string;
  FacadeFacing: string;
  GroundFloorType: string;
  QDCOneStarCreditApplied: string;
  ClassOneTotalArea: number;
  ClassTenATotalArea: number;
  TotalOutdoorArea: number;
  ThermalAnswer: string;
  WaterAnswer: string;
  EnergyAnswer: string;
  AchievedComplance: string;
  DoubleStoreyModelled: string;
  CeilingInsulation: string;
  CeilingFan: string;
  EnergyHeatingTarget: number;
  EnergyCoolingTarget: number;
  Walls: {
    additional_note: string;
    wall_color: string;
    glazing_frame_color: string;
    wallsData: any[];
  };
  Roofs: {
    CeilingFan: string;
    CeilingInsulation: string;
    additional_note: string;
    roofData: any[];
  };
  Floor: { additional_note: string; floorData: any[] };
  Windows: any[];
  ClassOne: any[];
  OutDoor: any[];
  Class10a: any[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface JobAttachment {
  JobAttachID: number;
  EmailAttachID: number;
  JobRecordID: number;
  JobAttachName: string;
  JobAttachExtension: string;
  JobAttachType: string;
  JobAttachCategory: 'public' | 'confidential';
  JobAttachLoc: string;
  JobAttachSize: number;
  CreatedBy: number;
  CreatedAt: string; // You can use a proper date type here if needed
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// Assessment Tabs
function CustomAssessmentTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yAssessmentProps(index: number) {
  return {
    id: `simple-assessment-tab-${index}`,
    'aria-controls': `simple-assessment-tabpanel-${index}`,
  };
}

export default function ViewJobReport() {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<JobRecord | null>(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const [tab_value, setTabValue] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState<any | null>(null);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [assessmentTabValue, setAssessmentTabValue] = React.useState(0);
  const [saveReportData, setSaveReportData] = useState<SaveReportData | null>(null);
  const [uploadConfirmation, setUploadConfirmation] = useState(false);
  const [mergeAttachments, setMergeAttachments] = useState(false);
  const [showGeneratePdfBtn, setShowGeneratePdfBtn] = useState(false);
  // console.log('Report data in view job report', saveReportData);
  const [generatePdfBtn, setGeneratePdfBtn] = useState('Generate PDF');
  const [viewPdfBtn, setViewPdfBtn] = useState('View PDF');
  // const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  // const [searchUsingRefNos, setSearchUsingRefNos] = useState(false);
  const [attachments, setAttachments] = useState<JobAttachment[] | null>(null);
  const [selectedAttachments, setSelectedAttachments] = useState<string[]>([]);
  const [showCertificateId, setShowCertificateId] = useState(false);
  const [certificateId, setCertificateId] = useState('');
  const [miniCertificateBase64, setMiniCertificateBase64] = useState('');
  const [showAssessmentReportTab, setShowAssessmentReportTab] = useState(false);
  // let DoubleStoreyModelled = '';
  const [DoubleStoreyModelled, setDoubleStoreyModelled] = useState('');
  // const client_names_list: string[] = [];
  const handleCheckboxChange = (event: any, attachmentLoc: string) => {
    if (event.target.checked) {
      // If checkbox is checked, add JobAttachLoc to the selectedAttachments array
      setSelectedAttachments([...selectedAttachments, attachmentLoc]);
      // console.log('Selected Attachments =  ', selectedAttachments);
    } else {
      // If checkbox is unchecked, remove JobAttachLoc from the selectedAttachments array
      setSelectedAttachments(selectedAttachments.filter((loc) => loc !== attachmentLoc));
      // console.log('Selected Attachments =  ', selectedAttachments);
    }
  };

  // const handleOpenDialog = () => {
  //   setOpenConfirmationDialog(true);
  // };

  // const handleCloseDialog = () => {
  //   setOpenConfirmationDialog(false);
  // };

  // const handleConfirmContinue = () => {
  //   setOpenConfirmationDialog(false);
  //   // Proceed with generating the PDF using reference numbers
  //   generatePdf(); // No argument is passed here
  // };

  // const handleCheckboxChangeRef = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchUsingRefNos(event.target.checked);
  // };

  const handleCertificateId = (certificateIdTemp: any) => {
    setCertificateId(certificateIdTemp);
    // console.log('Certificate Id = ', certificateId);
  };

  const getReportData = useCallback(() => {
    axios
      .get(`/core/getReportData?jobId=${id}`)
      .then((response) => {
        setReportData(response.data as any);
        const responseData = response.data;

        // const client_names = reportData?.eaConfigData?.KeywordValue || '';

        // if (client_names) {
        //   client_names.split(',').forEach((value: any) => {
        //     client_names_list.push(value.trim());
        //   });
        //   console.log(client_names_list);
        // } else {
        //   console.log('Client name is empty or undefined');
        // }

        // let zone = response.data?.projectInfoData?.Climate;
        // zone = zone.substring(0, 2);
        let zone = response.data?.projectInfoData?.Climate;

        zone = zone.trim();

        zone = zone.length > 1 ? zone.substring(0, 2) : zone;

        setAssessmentReport(response.data?.projectInfoData?.AssessmentReport);

        const { artificialLightningData } = responseData;
        const class1Data: any[] = [];
        const outerData: any[] = [];
        const class10AData: any[] = [];
        // setCertificateId(responseData?.projectInfoData?.CertificateID);
        setMiniCertificateBase64(responseData?.projectInfoData?.MiniCertificateBase64);
        if (artificialLightningData) {
          artificialLightningData.forEach((element: any) => {
            if (element.artificial_lightning_type === 'ClassOne') {
              class1Data.push(element);
            } else if (element.artificial_lightning_type === 'OutDoor') {
              outerData.push(element);
            } else if (element.artificial_lightning_type === 'Class10a') {
              class10AData.push(element);
            }
          });
        }

        let waterSectionOptions: any[] = [];
        let energySectionOptions: any[] = [];
        let thermalSectionOptions: any[] = [];
        if (responseData && responseData.optionsData) {
          waterSectionOptions = responseData.optionsData.filter(
            (item: any) => item.option_type === 'water'
          );

          energySectionOptions = responseData.optionsData.filter(
            (item: any) => item.option_type === 'energy'
          );

          thermalSectionOptions = responseData.optionsData.filter(
            (item: any) => item.option_type === '' || item.option_type === 'thermal'
          );
        }

        if (responseData.summarySpecificationData?.DoubleStoreyModelled === '') {
          setDoubleStoreyModelled('');
        } else if (
          responseData.summarySpecificationData?.DoubleStoreyModelled ===
          'This double storey has been modelled with restricted window openings (%) as per NCC Protection of Openable Windows Advisory Note'
        ) {
          setDoubleStoreyModelled('restricted window openings');
        } else {
          setDoubleStoreyModelled('fall-project screens');
        }

        setSaveReportData((prevData: any) => ({
          ...prevData,
          Rating: responseData.projectInfoData.Rating,
          DPNumber: responseData.summarySpecificationData.DpNumber,
          DwellingType: responseData.projectInfoData.DwellingType,
          GroundFloorType: responseData.summarySpecificationData.GroundFloorType,
          GarageSide: responseData.summarySpecificationData.GarageSide,
          FacadeFacing: responseData.summarySpecificationData.FacadeFacing,
          QDCOneStarCreditApplied: responseData.summarySpecificationData.QdcOneStarCreditApplied,
          AchievedComplance: responseData.summarySpecificationData?.AchievedComplance,
          DoubleStoreyModelled,
          CeilingInsulation: responseData.summarySpecificationData?.CeilingInsulation,
          CeilingFan: responseData.summarySpecificationData?.CeilingFan,
          ClassOneTotalArea: responseData.summarySpecificationData.ClassOneTotalArea,
          ClassTenATotalArea: responseData.summarySpecificationData.ClassTenTotalArea,
          TotalOutdoorArea: responseData.summarySpecificationData.TotalOutdoorArea,
          EnergyHeatingTarget: responseData.summarySpecificationData.EnergyHeatingTarget,
          EnergyCoolingTarget: responseData.summarySpecificationData.EnergyCoolingTarget,
          State: getState(response.data),
          NatHersClimateZone: zone,

          Options: thermalSectionOptions,
          Walls: {
            additional_note: responseData.summarySpecificationData.walls_note,
            wallsData: responseData.wallsData,
            wall_color: responseData.summarySpecificationData.wall_color,
            glazing_frame_color: responseData.summarySpecificationData.glazing_frame_color,
          },
          Roofs: {
            CeilingFan: responseData.summarySpecificationData.ceiling_fan,
            CeilingInsulation: responseData.summarySpecificationData.ceiling_insulation,
            additional_note: responseData.summarySpecificationData.roof_note,
            roofData: responseData.roofsceilingsData,
          },
          Floor: {
            additional_note: responseData.summarySpecificationData.floor_note,
            floorData: responseData.floorsData,
          },
          Windows: responseData.windowsData,
          ClassOne: class1Data,
          OutDoor: outerData,
          Class10a: class10AData,
          ThermalAnswer: responseData.summarySpecificationData.ThermalAnswer,
          WaterAnswer: responseData.summarySpecificationData.WaterAnswer,
          EnergyAnswer: responseData.summarySpecificationData.EnergyAnswer,
          WaterSectionOptions: waterSectionOptions,
          EnergySectionOptions: energySectionOptions,
        }));
        setShowAssessmentReportTab(true);
      })
      .catch((error) => {
        enqueueSnackbar(error?.error, {
          variant: 'error',
        });
        console.error('Error fetching getJob:', error);
      });

    function getState(responseData: any) {
      const postcodes = [
        '0200',
        '0221',
        '2600',
        '2601',
        '2602',
        '2603',
        '2604',
        '2605',
        '2606',
        '2607',
        '2608',
        '2609',
        '2610',
        '2611',
        '2612',
        '2614',
        '2615',
        '2616',
        '2617',
        '2900',
        '2901',
        '2902',
        '2903',
        '2904',
        '2905',
        '2906',
        '2911',
        '2912',
        '2913',
        '2914',
      ];
      const address = responseData.projectInfoData?.Address;
      const postcode = address.substring(address.length - 4);
      let siteState = 'No State Found';
      if (postcodes.indexOf(postcode) >= 0) {
        siteState = 'Australian Capital Territory';
      } else if (!Number.isNaN(postcode) && postcode.length === 4) {
        if (postcode.charAt(0) === '1' || postcode.charAt(0) === '2') {
          siteState = 'New South Wales';
        } else if (postcode.charAt(0) === '3') {
          siteState = 'Victoria';
        } else if (postcode.charAt(0) === '4') {
          siteState = 'Queensland';
        } else if (postcode.charAt(0) === '5') {
          siteState = 'South Australia';
        } else if (postcode.charAt(0) === '6') {
          siteState = 'Western Australia';
        } else if (postcode.charAt(0) === '7') {
          siteState = 'Tasmania';
        } else if (postcode.charAt(0) === '8') {
          siteState = 'Northern Territory';
        }
      }
      return siteState;
    }
  }, [DoubleStoreyModelled, enqueueSnackbar, id]);

  const getAttachmentInfo = useCallback(() => {
    axios
      .get(`/core/getAllAttachments?Id=${id}`)
      .then((response) => {
        // console.log('Attachment information = ', response.data);
        const filteredAttachments = response.data.filter(
          (attachment: any) => attachment?.JobAttachExtension === 'pdf'
        );

        setAttachments(filteredAttachments as JobAttachment[]);
      })
      .catch((error) => {
        console.error('Error fetching getAllAttachments:', error);
      });
  }, [id]);

  const openMergeAttachmentsDialog = () => {
    if (attachments && attachments.length > 0) {
      setMergeAttachments(true);
    } else {
      generatePdf();
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) {
      setTabValue(newValue);
    } else if (
      newValue === 1 &&
      reportData &&
      reportData.projectInfoData &&
      reportData.projectInfoData.NccType !== undefined &&
      reportData.projectInfoData.NccType !== ''
    ) {
      setTabValue(newValue);

      const validateGeneratePDF = validateReportData();
      // console.log('Validate generate pdf ', validateGeneratePDF);
      if (validateGeneratePDF.length === 0) {
        setShowGeneratePdfBtn(true);
      }
    } else {
      enqueueSnackbar('Please upload provisional diagnostic report to proceed', {
        variant: 'error',
      });
      setTabValue(0);
    }
  };
  const handleAssessmentTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setAssessmentTabValue(newValue);
  };
  const [files, setFiles] = useState<(File | string)[]>([]);
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles([...files, ...newFiles]);

      if (
        reportData &&
        reportData.projectInfoData &&
        reportData.projectInfoData.NccType !== undefined &&
        reportData.projectInfoData.NccType !== ''
      ) {
        setUploadConfirmation(true);
      }
    },
    [files, reportData]
  );

  // const handleRemoveDocumentFile = (inputFile: File | string) => {
  //   const filtered = files.filter((file) => file !== inputFile);
  //   setFiles(filtered);
  // };

  const handleCancelDelete = () => {
    setUploadConfirmation(false);
    setMergeAttachments(false);
  };
  const handleUpload = async () => {
    try {
      setUploadConfirmation(false);
      setLoading(true);
      const formData = new FormData();

      files.forEach((file, index) => {
        if (file instanceof File) {
          formData.append(`file_${index}`, file);
        }
      });

      let jobDwelling = '';
      if (data?.JobDwelling === 'single storey' || data?.JobDwelling === 'single storey granny') {
        jobDwelling = 'Single Storey';
      } else if (
        data?.JobDwelling === 'double storey' ||
        data?.JobDwelling === 'double storey granny'
      ) {
        jobDwelling = 'Double Storey';
      } else if (
        data?.JobDwelling === 'double storey +' ||
        data?.JobDwelling === 'double storey ++'
      ) {
        jobDwelling = 'Multi-Level Dwelling';
      }

      const response = await axios.post(
        `/core/uploadReport?Id=${id}&jobDwelling=${jobDwelling}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      enqueueSnackbar(response.data);
      setFiles([]);
      getReportData();
      // console.log('Upload response:', response.data);
    } catch (error) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      enqueueSnackbar(error, { variant: 'error' });

      console.error('Error uploading files:', error);
    }
  };

  const handleRemoveFile = (inputFile: File | string) => {
    const filtered = files.filter((file) => file !== inputFile);
    setFiles(filtered);
  };
  const CommentSchema = Yup.object().shape({
    // JobAssessorID: Yup.string().required('Assessor is required'),
    JobType: Yup.string().required('Job Type is required'),
    JobDwelling: Yup.string().required('Job Dwelling is required'),
    // JobIsUrgent: Yup.string().required('Job Type is required'),
    JobFloors: Yup.string().required('Job Floor is required'),
    JobEmailSubject: Yup.string().required('Email subject is required'),
    JobPrelimFinal: Yup.string().required('Prelim or final is required'),
    // JobRating: Yup.string().required('Job Rating is required'),
    JobAddrState: Yup.string().required('State is required'),
    JobStatus: Yup.string().required('Status is required'),
    JobSiteAddr: Yup.string().required('Address is required'),
    JobRequestorID: Yup.string().required('Requestor is required'),
  });
  const defaultValues = {
    JobAssessorID: '',
    JobNumber: '',
    JobType: '',
    JobDwelling: '',
    // JobIsUrgent: false,
    JobPostAssessmentFlag: true,
    JobFloors: 0,
    JobEmailSubject: '',
    JobPrelimFinal: '',
    JobRating: 0,
    JobStatus: '',
    JobAddrState: '',
    JobRequestorID: '',
    JobSiteAddr: '',
    JobCode: '',
  };
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });
  const { setValue } = methods;
  const { themeStretch } = useSettingsContext();

  const [assessmentReport, setAssessmentReport] = useState<string>('');

  const getJobInfo = useCallback(() => {
    axios
      .get(`/core/getJob?Id=${id}`)
      .then((response) => {
        setData(response.data as JobRecord);
        if (response.data.JobAssessorID === 0) {
          setValue('JobAssessorID', '0');
        } else {
          setValue('JobAssessorID', response.data.JobAssessorID);
        }

        setValue('JobType', response.data.JobType);
        setValue('JobNumber', response.data.JobNumber);
        setValue('JobDwelling', response.data.JobDwelling);
        setValue('JobIsUrgent', response.data.JobIsUrgent !== '');
        setValue('JobPostAssessmentFlag', response.data.JobPostAssessmentFlag !== '');
        setValue('JobCode', response.data.JobCode);
        setValue('JobFloors', response.data.JobFloors === 0 ? '0' : response.data.JobFloors);
        setValue('JobStatus', response.data.JobStatus);
        setValue('JobEmailSubject', response.data.JobEmailSubject);
        setValue('JobPrelimFinal', response.data.JobPrelimFinal);
        setValue('JobRating', response.data.JobRating);
        setValue('JobAddrState', response.data.JobAddrState);
        setValue('JobSiteAddr', response.data.JobSiteAddr);
        setValue('JobRequestorID', response.data.JobRequestorID);
      })
      .catch((error) => {
        console.error('Error fetching getJob:', error);
      });
  }, [id, setValue]);

  useEffect(() => {
    getJobInfo();
  }, [getJobInfo]);

  useEffect(() => {
    getReportData();
  }, [getReportData]);

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };
  // const [comments, setComments] = useState<JobComment[] | null>([]);
  // const [issubmitting, setIsSubmitting] = useState(false);
  // const [profilePics, setProfilePics] = useState<any>();

  const submitReportData = () => {
    const validationResponse = validateReportData();
    setInvalidFields(validationResponse);
    if (validationResponse.indexOf('Rating') >= 0 || validationResponse.indexOf('DPNumber') >= 0) {
      let event: any;
      handleAssessmentTabChange(event, 3);
      enqueueSnackbar('Please Fill Mandatory Fields', { variant: 'error' });
    } else if (validationResponse.indexOf('Options') >= 0) {
      let event: any;
      handleAssessmentTabChange(event, 1);
      enqueueSnackbar('Please Fill Mandatory Fields', { variant: 'error' });
    } else if (
      validationResponse.indexOf('WaterSectionOptions') >= 0 ||
      validationResponse.indexOf('EnergySectionOptions') >= 0 ||
      validationResponse.indexOf('ThermalAnswer') >= 0 ||
      validationResponse.indexOf('WaterAnswer') >= 0 ||
      validationResponse.indexOf('EnergyAnswer') >= 0
    ) {
      let event: any;
      handleAssessmentTabChange(event, 2);

      enqueueSnackbar('Please Fill Mandatory Fields', { variant: 'error' });
    } else if (
      validationResponse.indexOf('DwellingType') >= 0 ||
      validationResponse.indexOf('GroundFloorType') >= 0 ||
      (reportData?.projectInfoData?.NccType === '2022' &&
        (validationResponse.indexOf('GarageSide') >= 0 ||
          validationResponse.indexOf('FacadeFacing') >= 0)) ||
      validationResponse.indexOf('GarageSide') >= 0 ||
      validationResponse.indexOf('FacadeFacing') >= 0 ||
      validationResponse.indexOf('ClassOneTotalArea') >= 0 ||
      validationResponse.indexOf('ClassTenATotalArea') >= 0 ||
      validationResponse.indexOf('TotalOutdoorArea') >= 0 ||
      validationResponse.indexOf('QDCOneStarCreditApplied') >= 0
    ) {
      let event: any;
      handleAssessmentTabChange(event, 3);
      enqueueSnackbar('Please Fill Mandatory Fields', { variant: 'error' });
    } else if (
      validationResponse.indexOf('WallsAdditionalNote') >= 0 ||
      validationResponse.indexOf('RoofsAdditionalNote') >= 0 ||
      validationResponse.indexOf('FloorAdditionalNote') >= 0 ||
      validationResponse.indexOf('AchievedComplance') >= 0 || // 2022
      validationResponse.indexOf('DoubleStoreyModelled') >= 0 || // 2022
      validationResponse.indexOf('CeilingInsulation') >= 0 || // 2022
      validationResponse.indexOf('CeilingFan') >= 0 // 2022
    ) {
      let event: any;
      handleAssessmentTabChange(event, 6);
      enqueueSnackbar('Please Fill Mandatory Fields', { variant: 'error' });
    } else if (
      validationResponse.some((item) => item.includes('WallAdditionalNote')) ||
      validationResponse.some((item) => item.includes('RoofSparking')) || // 2019
      validationResponse.some((item) => item.includes('RoofThermalBreak')) || // 2022
      validationResponse.some((item) => item.includes('GlazeCharacteristics')) ||
      validationResponse.some((item) => item.includes('GlazeGlazingType')) ||
      validationResponse.some((item) => item.includes('GlazeAdditionalNote'))
    ) {
      let event: any;
      handleAssessmentTabChange(event, 6);
      enqueueSnackbar('Please Fill Mandatory Fields', { variant: 'error' });
    } else if (
      validationResponse.indexOf('LightClassOne') >= 0 ||
      validationResponse.indexOf('LightClassTenA') >= 0 ||
      validationResponse.indexOf('LightOutDoor') >= 0
    ) {
      let event: any;
      handleAssessmentTabChange(event, 7);
      enqueueSnackbar('Please Fill Mandatory Fields', { variant: 'error' });
    } else if (
      validationResponse.some((item) => item.includes('LightClassOneMounting')) ||
      validationResponse.some((item) => item.includes('LightClassOneFitting')) ||
      validationResponse.some((item) => item.includes('LightClassOneWattage')) ||
      validationResponse.some((item) => item.includes('LightClassOneQuantity')) ||
      validationResponse.some((item) => item.includes('LightClassOneTotal')) ||
      validationResponse.some((item) => item.includes('LightClassTenAMounting')) ||
      validationResponse.some((item) => item.includes('LightClassTenAFitting')) ||
      validationResponse.some((item) => item.includes('LightClassTenAWattage')) ||
      validationResponse.some((item) => item.includes('LightClassTenAQuantity')) ||
      validationResponse.some((item) => item.includes('LightClassTenATotal')) ||
      validationResponse.some((item) => item.includes('LightOutDoorAMounting')) ||
      validationResponse.some((item) => item.includes('LightOutDoorFitting')) ||
      validationResponse.some((item) => item.includes('LightOutDoorWattage')) ||
      validationResponse.some((item) => item.includes('LightOutDoorQuantity')) ||
      validationResponse.some((item) => item.includes('LightOutDoorTotal'))
    ) {
      let event: any;
      handleAssessmentTabChange(event, 7);
      enqueueSnackbar('Please Fill Mandatory Fields', { variant: 'error' });
    }

    // if (validationResponse.length !== 0) {
    //   enqueueSnackbar('Please Fill Mandatory Fields', { variant: 'error' });
    // }
    if (validationResponse.length === 0) {
      setShowCertificateId(true);
      setShowGeneratePdfBtn(true);
      console.log('Report data in submitReportData axios call', saveReportData);
      axios
        .post(`/core/submitReportData?jobId=${id}`, saveReportData)
        .then((response) => {
          enqueueSnackbar(response.data);
          setCertificateId('');
          getReportData();
          setAssessmentReport('');
        })
        .catch((error) => {
          console.error('Error getting report data:', error);
          enqueueSnackbar(error, { variant: 'error' });
        });
    }
  };

  useEffect(() => {
    // console.log('Validation response', invalidFields);
  }, [invalidFields]);

  const generatePdf = () => {
    setGeneratePdfBtn('Generating PDF...');
    setMergeAttachments(false);
    const mergeAttachmentsPayload = {
      jobId: id,
      attachmentLocation: selectedAttachments,
      certificateId,
    };
    axios
      .post(`/core/generatePdf`, mergeAttachmentsPayload)
      .then((response) => {
        setAssessmentReport('Generated');
        enqueueSnackbar(response.data);
        setGeneratePdfBtn('Generate PDF');
      })
      .catch((error) => {
        console.error('Error getting report data:', error);
        if (error === 'No Certificate Id found') {
          setShowCertificateId(true);
        }
        enqueueSnackbar(error, { variant: 'error' });
        setGeneratePdfBtn('Generate PDF');
      });
  };

  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [openFile, setFileOpen] = useState(false);

  const handleFileClose = () => setFileOpen(false);

  const docViewerRef = useRef<DocViewerRef>(null);

  const downloadPdf = () => {
    setViewPdfBtn('Fetching PDF...');
    const fileName = 'EA_FINAL_ASSESSMENT_REPORT';
    const fileNameWithDate = `${fileName}_${id}`;

    axios
      .get(`/core/downloadPdf?jobId=${id}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const blob = response.data;
        const objectUrl = URL.createObjectURL(blob);
        setDocuments([{ uri: objectUrl, fileType: 'pdf', fileName: fileNameWithDate }]);
        setFileOpen(true);
        setViewPdfBtn('View PDF');
      })
      .catch((error) => {
        console.error('Error downloading report:', error);
        enqueueSnackbar(error, { variant: 'error' });
        setViewPdfBtn('View PDF');
      });
  };

  const downloadMiniCertificate = () => {
    try {
      // Assuming miniCertificateBase64 is already available
      const base64 = miniCertificateBase64;

      // Convert base64 string to File using base64ToFile function
      const file = base64ToFile(base64, 'mini_certificate.png');

      // Create a download link using File URL
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(file);
      downloadLink.download = file.name;

      // Append the link to the document body and trigger the click event
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Remove the link from the document body after the download
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error('Error downloading mini-certificate:', error);
      // Handle the error, show a message to the user, or log it as needed
    }
  };

  // Your existing base64ToFile function
  const base64ToFile = (base64String: string, fileName: string) => {
    const arr: any = base64String.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  };

  const passOrFailJob = () => {
    let failValue: number = 7;
    if (reportData?.projectInfoData.NccType === '2019') {
      if (
        saveReportData?.State === 'Queensland' &&
        saveReportData?.QDCOneStarCreditApplied === 'Yes'
      ) {
        failValue = 5;
      } else {
        failValue = 6;
      }
    } else if (reportData?.projectInfoData.NccType === '2022') {
      if (
        saveReportData?.State === 'Queensland' &&
        saveReportData?.QDCOneStarCreditApplied === 'Yes'
      ) {
        failValue = 6;
      } else {
        failValue = 7;
      }
    }

    let jobPass = true;
    if (
      saveReportData?.State === 'New South Wales' &&
      (saveReportData?.NatHersClimateZone === '9' ||
        saveReportData?.NatHersClimateZone === '10' ||
        saveReportData?.NatHersClimateZone === '11')
    ) {
      if (
        (saveReportData?.EnergyHeatingTarget !== undefined &&
          reportData?.projectInfoData?.EnergyHeating > saveReportData.EnergyHeatingTarget) ||
        (saveReportData?.EnergyCoolingTarget !== undefined &&
          reportData?.projectInfoData.EnergyCooling > saveReportData.EnergyCoolingTarget)
      ) {
        jobPass = false;
      }
    }
    // console.log('');
    else if (
      (saveReportData?.Rating !== undefined && saveReportData?.Rating < failValue) ||
      (saveReportData?.EnergyHeatingTarget !== undefined &&
        reportData?.projectInfoData?.EnergyHeating > saveReportData.EnergyHeatingTarget) ||
      (saveReportData?.EnergyCoolingTarget !== undefined &&
        reportData?.projectInfoData.EnergyCooling > saveReportData.EnergyCoolingTarget)
    ) {
      jobPass = false;
    }

    return jobPass;
  };
  const showOptionPage = () => {
    let show = false;
    if (
      saveReportData?.State === 'New South Wales' &&
      (saveReportData?.ThermalAnswer === 'no' || !passOrFailJob())
    ) {
      show = true;
    } else if (!passOrFailJob()) {
      show = true;
    }

    return show;
  };

  const validateReportData = () => {
    const fields = [];
    // Cover Page
    if (
      saveReportData?.State === 'New South Wales' &&
      (saveReportData?.NatHersClimateZone === '9' ||
        saveReportData?.NatHersClimateZone === '10' ||
        saveReportData?.NatHersClimateZone === '11')
    ) {
      // Validate The DP Number
      if (
        saveReportData?.DPNumber === undefined ||
        saveReportData?.DPNumber === null ||
        Number.isNaN(saveReportData?.DPNumber || saveReportData?.DPNumber === 0)
      ) {
        fields.push('DPNumber');
      }
    } else if (
      saveReportData?.Rating === undefined ||
      saveReportData?.Rating === null ||
      Number.isNaN(saveReportData?.Rating) ||
      saveReportData?.Rating === 0
    ) {
      fields.push('Rating');
    }

    // Option Page
    if (
      showOptionPage() &&
      (saveReportData?.Options === undefined || saveReportData?.Options.length <= 0)
    ) {
      fields.push('Options');
    }

    // Basix Option Page
    if (saveReportData?.State === 'New South Wales') {
      if (
        saveReportData?.ThermalAnswer === undefined ||
        saveReportData?.ThermalAnswer === null ||
        saveReportData?.ThermalAnswer === ''
      ) {
        fields.push('ThermalAnswer');
      }
      if (
        saveReportData?.WaterAnswer === undefined ||
        saveReportData?.WaterAnswer === null ||
        saveReportData?.WaterAnswer === ''
      ) {
        fields.push('WaterAnswer');
      }
      if (
        saveReportData?.EnergyAnswer === undefined ||
        saveReportData?.EnergyAnswer === null ||
        saveReportData?.EnergyAnswer === ''
      ) {
        fields.push('EnergyAnswer');
      }

      if (
        saveReportData?.WaterAnswer === 'no' &&
        (saveReportData?.WaterSectionOptions === undefined ||
          saveReportData?.WaterSectionOptions.length <= 0)
      ) {
        fields.push('WaterSectionOptions');
      }
      if (
        saveReportData?.EnergyAnswer === 'no' &&
        (saveReportData?.EnergySectionOptions === undefined ||
          saveReportData?.EnergySectionOptions.length <= 0)
      ) {
        fields.push('EnergySectionOptions');
      }
    }

    // Summary Page
    if (saveReportData?.DwellingType === undefined || saveReportData?.DwellingType === '') {
      fields.push('DwellingType');
    }
    if (saveReportData?.GroundFloorType === undefined || saveReportData?.GroundFloorType === '') {
      fields.push('GroundFloorType');
    }
    if (reportData?.projectInfoData?.NccType === '2022') {
      if (saveReportData?.GarageSide === undefined || saveReportData?.GarageSide === '') {
        fields.push('GarageSide');
      }
      if (saveReportData?.FacadeFacing === undefined || saveReportData?.FacadeFacing === '') {
        fields.push('FacadeFacing');
      }
    }
    if (
      saveReportData?.State === 'Queensland' &&
      (saveReportData?.QDCOneStarCreditApplied === undefined ||
        saveReportData?.QDCOneStarCreditApplied === '')
    ) {
      fields.push('QDCOneStarCreditApplied');
    }
    if (saveReportData?.ClassOneTotalArea === undefined || saveReportData?.ClassOneTotalArea <= 0) {
      fields.push('ClassOneTotalArea');
    }
    if (
      saveReportData?.ClassTenATotalArea === undefined ||
      saveReportData?.ClassTenATotalArea <= 0
    ) {
      fields.push('ClassTenATotalArea');
    }
    if (saveReportData?.TotalOutdoorArea === undefined || saveReportData?.TotalOutdoorArea <= 0) {
      fields.push('TotalOutdoorArea');
    }

    // Specification Page
    if (
      saveReportData?.Walls?.additional_note === undefined ||
      saveReportData?.Walls?.additional_note === ''
    ) {
      fields.push('WallsAdditionalNote');
    }
    if (reportData?.projectInfoData?.NccType === '2022') {
      if (reportData?.projectInfoData?.ClientName.includes('Metricon')) {
        if (
          saveReportData?.AchievedComplance === undefined ||
          saveReportData?.AchievedComplance === ''
        ) {
          fields.push('AchievedComplance');
        }
      }
    }
    if (reportData?.projectInfoData?.NccType === '2022') {
      if (saveReportData?.DwellingType === 'Double Storey') {
        if (
          saveReportData?.DoubleStoreyModelled === undefined ||
          saveReportData?.DoubleStoreyModelled === ''
        ) {
          fields.push('DoubleStoreyModelled');
        }
      }
    }
   
    // if (reportData?.projectInfoData?.ClientName.includes('Metricon')) {
    //   if (
    //     saveReportData?.CeilingInsulation === undefined ||
    //     saveReportData?.CeilingInsulation === ''
    //   ) {
    //     fields.push('CeilingInsulation');
    //   }
    // }
    // if (reportData?.projectInfoData?.ClientName.includes('Metricon')) {
    //   if (saveReportData?.CeilingFan === undefined || saveReportData?.CeilingFan === '') {
    //     fields.push('CeilingFan');
    //   }
    // }

    if (
      saveReportData?.Roofs?.additional_note === undefined ||
      saveReportData?.Roofs?.additional_note === ''
    ) {
      fields.push('RoofsAdditionalNote');
    }
    if (
      saveReportData?.Floor?.additional_note === undefined ||
      saveReportData?.Floor?.additional_note === ''
    ) {
      fields.push('FloorAdditionalNote');
    }

    // Walls
    if (
      reportData?.projectInfoData?.NccType === 2019 &&
      saveReportData?.Walls?.wallsData &&
      saveReportData?.Walls?.wallsData.length > 0
    ) {
      const newFiles = saveReportData?.Walls?.wallsData.map((wall, index) => {
        if (wall.additional_note === undefined || wall.additional_note === '') {
          fields.push(`${index}-WallAdditionalNote`);
        }
        return wall;
      });
    }

    // Roofs And Ceiling
    if (saveReportData?.Roofs?.roofData && saveReportData?.Roofs?.roofData.length > 0) {
      const newFiles = saveReportData?.Roofs?.roofData.map((roof, index) => {
        if (reportData?.projectInfoData.NccType === '2019') {
          if (roof.sparking === undefined || roof.sparking === '') {
            fields.push(`${index}-RoofSparking`);
          }
        }
        
        if (reportData?.projectInfoData.NccType === '2022') {
          if (roof.thermal_break === undefined || roof.thermal_break === '') {
            fields.push(`${index}-RoofThermalBreak`);
          }
        }

        return roof;
      });
    }

    // Glazing/Windows
    if (saveReportData?.Windows && saveReportData?.Windows.length > 0) {
      const newFiles = saveReportData?.Windows.map((window, index) => {
        if (window.characteristics === undefined || window.characteristics === '') {
          fields.push(`${index}-GlazeCharacteristics`);
        }
        if (window.glazing_type === undefined || window.glazing_type === '') {
          fields.push(`${index}-GlazeGlazingType`);
        }
        if (window.additional_note === undefined || window.additional_note === '') {
          fields.push(`${index}-GlazeAdditionalNote`);
        }
        return window;
      });
    }

    // Artifical Lighting Page
    if (
      reportData?.projectInfoData?.NccType === '2019' &&
      (reportData?.projectInfoData?.ClientName === 'Metricon Homes Regional North' ||
        reportData?.projectInfoData?.ClientName === 'EveryOne Homes')
    ) {
      // Class 1 Dwelling
      if (saveReportData?.ClassOne === undefined || saveReportData?.ClassOne.length <= 0) {
        fields.push('LightClassOne');
      }
      if (saveReportData?.Class10a === undefined || saveReportData?.Class10a.length <= 0) {
        fields.push('LightClassTenA');
      }
      if (saveReportData?.OutDoor === undefined || saveReportData?.OutDoor.length <= 0) {
        fields.push('LightOutDoor');
      }

      if (saveReportData?.ClassOne !== undefined && saveReportData?.ClassOne.length > 0) {
        const newFiles = saveReportData?.ClassOne.map((item, index) => {
          if (item.mounting === undefined || item.mounting === '') {
            fields.push(`${index}-LightClassOneMounting`);
          }
          if (item.fitting === undefined || item.fitting === '') {
            fields.push(`${index}-LightClassOneFitting`);
          }
          if (item.wattage === undefined || item.wattage === '') {
            fields.push(`${index}-LightClassOneWattage`);
          }
          if (item.quantity === undefined || item.quantity === '') {
            fields.push(`${index}-LightClassOneQuantity`);
          }
          if (item.total === undefined || item.total === '') {
            fields.push(`${index}-LightClassOneTotal`);
          }
          return item;
        });
      }

      // Class 10 A
      if (saveReportData?.Class10a !== undefined && saveReportData?.Class10a.length > 0) {
        const newFiles = saveReportData?.Class10a.map((item, index) => {
          if (item.mounting === undefined || item.mounting === '') {
            fields.push(`${index}-LightClassTenAMounting`);
          }
          if (item.fitting === undefined || item.fitting === '') {
            fields.push(`${index}-LightClassTenAFitting`);
          }
          if (item.wattage === undefined || item.wattage === '') {
            fields.push(`${index}-LightClassTenAWattage`);
          }
          if (item.quantity === undefined || item.quantity === '') {
            fields.push(`${index}-LightClassTenAQuantity`);
          }
          if (item.total === undefined || item.total === '') {
            fields.push(`${index}-LightClassTenATotal`);
          }
          return item;
        });
      }

      // OutDoor
      if (saveReportData?.OutDoor !== undefined && saveReportData?.OutDoor.length > 0) {
        const newFiles = saveReportData?.OutDoor.map((item, index) => {
          if (item.mounting === undefined || item.mounting === '') {
            fields.push(`${index}-LightOutDoorAMounting`);
          }
          if (item.fitting === undefined || item.fitting === '') {
            fields.push(`${index}-LightOutDoorFitting`);
          }
          if (item.wattage === undefined || item.wattage === '') {
            fields.push(`${index}-LightOutDoorWattage`);
          }
          if (item.quantity === undefined || item.quantity === '') {
            fields.push(`${index}-LightOutDoorQuantity`);
          }
          if (item.total === undefined || item.total === '') {
            fields.push(`${index}-LightOutDoorTotal`);
          }
          return item;
        });
      }
    }
    return fields;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => {
  //   const validateGeneratePDF = validateReportData();
  //   console.log('Validate generate pdf ', validateGeneratePDF);
  //   if (validateGeneratePDF.length === 0) {
  //     setShowGeneratePdfBtn(true);
  //   }
  // });

  return (
    <Container sx={{ maxWidth: '100% !important', paddingTop: '50px', paddingBottom: '50px' }}>
      <Modal
        open={openFile}
        onClose={handleFileClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Scrollbar>
          openJobComp
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box
              sx={{
                height: '100%',
                width: '900px',
              }}
            >
              {' '}
              <IconButton
                onClick={handleFileClose}
                sx={{
                  position: 'fixed',
                  zIndex: '999',
                  top: '10px',
                  right: '10px',
                  color: 'aliceblue',
                }}
              >
                <CloseIcon />
              </IconButton>
              <DocViewer
                documents={documents}
                ref={docViewerRef}
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: false,
                    retainURLParams: false,
                  },
                  csvDelimiter: ',',
                  pdfZoom: {
                    defaultZoom: 1.1,
                    zoomJump: 0.2,
                  },

                  pdfVerticalScrollByDefault: true,
                }}
                pluginRenderers={DocViewerRenderers}
              />
            </Box>
          </Box>
        </Scrollbar>
      </Modal>

      <Helmet>
        <title> Job Report | Energy Advance</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Job Report"
          links={[
            {
              name: 'Jobs',
              href: '/jobs',
            },
            { name: 'View Job Report' },
          ]}
        />
        <Typography typography="h6" color="#788490">
          Job Id: {id}
        </Typography>

        {tab_value === 1 && (
          <>
            <Button
              variant="contained"
              size="medium"
              sx={{ borderRadius: 8, float: 'right', marginLeft: '10px' }}
              onClick={submitReportData}
            >
              Save
            </Button>
            {assessmentReport === '' && showGeneratePdfBtn && (
              <Tooltip
                title="Note : If no certificate ID is provided, it will be searched using the job reference. For more accurate results, it's recommended to pass the certificate ID."
                placement="top-end"
              >
                <Button
                  variant="contained"
                  size="medium"
                  sx={{
                    borderRadius: 8,
                    float: 'right',
                    marginLeft: '10px',
                  }}
                  onClick={openMergeAttachmentsDialog}
                >
                  {generatePdfBtn}
                </Button>
              </Tooltip>
            )}
            {assessmentReport === 'Generated' &&
              passOrFailJob() === true &&
              miniCertificateBase64 !== '' && (
                <Button
                  variant="contained"
                  size="medium"
                  sx={{
                    borderRadius: 8,
                    float: 'right',
                    marginLeft: '10px',
                  }}
                  onClick={downloadMiniCertificate}
                >
                  Download Mini Certificate
                </Button>
              )}
            {assessmentReport === 'Generated' && (
              <Button
                variant="contained"
                size="medium"
                sx={{
                  borderRadius: 8,
                  float: 'right',
                  marginLeft: '10px',
                }}
                onClick={downloadPdf}
              >
                {viewPdfBtn}
              </Button>
            )}
            {showCertificateId && (
              <TextField
                type="text"
                name="certificateId"
                value={certificateId}
                placeholder="Enter Certificate Id"
                onChange={(event) => {
                  handleCertificateId(event.target.value);
                }}
                sx={{
                  float: 'right',
                  marginLeft: '10px',
                  marginBottom: '10px',
                }}
              />
            )}
          </>
        )}

        {/* Confirmation Dialog */}
        {/* <Dialog
          open={openConfirmationDialog}
          onClose={handleCloseDialog}
          aria-labelledby="confirmation-dialog-title"
          aria-describedby="confirmation-dialog-description"
        >
          <DialogTitle id="confirmation-dialog-title">No Certificate ID</DialogTitle>
          <DialogContent>
            <DialogContentText id="confirmation-dialog-description">
              We will search using reference numbers. Do you want to continue?
            </DialogContentText>
            <FormControlLabel
              control={
                <Checkbox
                  checked={searchUsingRefNos}
                  onChange={(e) => setSearchUsingRefNos(e.target.checked)}
                />
              }
              label="Continue"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleConfirmContinue} disabled={!searchUsingRefNos}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Button onClick={handleOpenDialog}>
          Open Confirmation Dialog
        </Button> */}

        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab_value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Upload Report" {...a11yProps(0)} />
              {showAssessmentReportTab && (
                <Tab label="Assessment Report" {...a11yProps(1)} onClick={getAttachmentInfo} />
              )}
            </Tabs>
          </Box>
          <CustomTabPanel value={tab_value} index={0}>
            <Container
              sx={{ maxWidth: '80% !important', paddingTop: '20px', paddingBottom: '10px' }}
            >
              <Grid sx={{ paddingBottom: '20px' }}>
                <Upload
                  disabled={files.length === 1}
                  accept={{ 'application/pdf': [] }}
                  files={files}
                  onDrop={handleDrop}
                  onRemove={handleRemoveFile}
                />
              </Grid>
              {files.length === 1 && (
                <Grid item xs={12}>
                  <Typography variant="h6">Selected File:</Typography>
                </Grid>
              )}
              {files.map((file: File | string, index: number) => (
                <Grid
                  item
                  xs={12}
                  key={file instanceof File ? file.name : file}
                  sx={{
                    border: '1px solid grey',
                    borderRadius: '4px',
                    padding: 1,
                    marginBottom: 1,
                  }}
                >
                  <Stack direction="row" alignItems="center">
                    <Typography>{file instanceof File ? file.name : file}</Typography>
                    {/* <IconButton size="small" onClick={() => handleRemoveDocumentFile(file)}>
                      <CloseIcon />
                    </IconButton> */}
                  </Stack>
                </Grid>
              ))}
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:cloud-upload-fill" />}
                onClick={handleUpload}
                disabled={files.length < 1}
              >
                {loading ? 'Uploading...' : 'Upload'}
              </Button>

              {!!files.length && (
                <Button
                  disabled={loading}
                  variant="outlined"
                  color="inherit"
                  onClick={handleRemoveAllFiles}
                  sx={{ marginLeft: '10px' }}
                >
                  Remove
                </Button>
              )}
            </Container>
          </CustomTabPanel>

          <Dialog open={uploadConfirmation} onClose={handleCancelDelete}>
            <DialogTitle>Re-Upload Report</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Report Is Already Uploaded For This Job. If You Want To Upload It Again Previous
                Data Will Be Overwritten?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleUpload} color="primary">
                Yes
              </Button>
              <Button onClick={handleCancelDelete} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={mergeAttachments} onClose={handleCancelDelete}>
            <DialogTitle>Merge Attachments</DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                {attachments?.map((attachment, index) => (
                  <Grid item xs={6} key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedAttachments.includes(attachment.JobAttachLoc)}
                          onChange={(event) => handleCheckboxChange(event, attachment.JobAttachLoc)}
                        />
                      }
                      label={attachment.JobAttachName}
                    />
                  </Grid>
                ))}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={generatePdf} color="primary">
                Merge
              </Button>
              <Button onClick={handleCancelDelete} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <CustomTabPanel value={tab_value} index={1}>
            {reportData && (
              <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={assessmentTabValue}
                    onChange={handleAssessmentTabChange}
                    aria-label="Assessment Tabs"
                  >
                    <Tab value={0} label="Cover Page" {...a11yAssessmentProps(0)} />
                    {/* {handleOptionTab() && <Tab value={1} label="The Options" {...a11yAssessmentProps(1)} />} */}
                    {showOptionPage() && passOrFailJob() === false && (
                      <Tab value={1} label="The Options" {...a11yAssessmentProps(1)} />
                    )}
                    {saveReportData?.State === 'New South Wales' && (
                      <Tab value={2} label="BASIX Options" {...a11yAssessmentProps(2)} />
                    )}
                    <Tab value={3} label="The Summary" {...a11yAssessmentProps(3)} />
                    <Tab value={4} label="The Analysis" {...a11yAssessmentProps(4)} />
                    {/* {reportData?.projectInfoData?.NccType === '2019' && ( */}
                    <Tab value={5} label="The Analysis 2" {...a11yAssessmentProps(5)} />
                    {/* )} */}
                    <Tab value={6} label="The Specifications" {...a11yAssessmentProps(6)} />
                    {reportData?.eaConfigData?.KeywordValue &&
                      (reportData.eaConfigData.KeywordValue.includes(
                        reportData?.projectInfoData?.ClientName
                      ) ||
                        reportData.eaConfigData.KeywordValue.includes(
                          reportData?.projectInfoData?.ClientName
                        )) &&
                      reportData?.projectInfoData?.NccType === '2019' && (
                    <Tab value={7} label="Artificial Lighting" {...a11yAssessmentProps(7)} />
                    )} 
                    {(reportData?.projectInfoData?.NccType === '2022' ||
                      (reportData?.projectInfoData?.NccType === '2019' &&
                        saveReportData !== null &&
                        saveReportData.State !== 'New South Wales')) && (
                      <Tab value={8} label="Regulations 1" {...a11yAssessmentProps(8)} />
                    )}
                    {(reportData?.projectInfoData?.NccType === '2022' ||
                      (reportData?.projectInfoData?.NccType === '2019' &&
                        saveReportData !== null &&
                        saveReportData.State !== 'New South Wales')) && (
                      <Tab value={9} label="Regulations 2" {...a11yAssessmentProps(9)} />
                    )}
                    {reportData?.projectInfoData?.NccType === '2019' &&
                      saveReportData !== null &&
                      saveReportData.State === 'New South Wales' && (
                        <Tab value={10} label="NSW Regulations" {...a11yAssessmentProps(10)} />
                      )}{' '}
                  </Tabs>
                </Box>

                <CustomAssessmentTabPanel value={assessmentTabValue} index={0}>
                  <Cover
                    data={data}
                    reportData={reportData}
                    saveReportData={saveReportData}
                    setSaveReportData={setSaveReportData}
                    invalidFields={invalidFields}
                  />
                </CustomAssessmentTabPanel>

                <CustomAssessmentTabPanel value={assessmentTabValue} index={1}>
                  <Options
                    reportData={reportData}
                    saveReportData={saveReportData}
                    setSaveReportData={setSaveReportData}
                    invalidFields={invalidFields}
                  />
                </CustomAssessmentTabPanel>

                <CustomAssessmentTabPanel value={assessmentTabValue} index={2}>
                  <BasicOptions
                    reportData={reportData}
                    saveReportData={saveReportData}
                    setSaveReportData={setSaveReportData}
                    invalidFields={invalidFields}
                  />
                </CustomAssessmentTabPanel>

                <CustomAssessmentTabPanel value={assessmentTabValue} index={3}>
                  <Summary
                    reportData={reportData}
                    saveReportData={saveReportData}
                    setSaveReportData={setSaveReportData}
                    invalidFields={invalidFields}
                  />
                </CustomAssessmentTabPanel>

                {/* {reportData?.projectInfoData?.NccType === '2019' ? ( */}
                <CustomAssessmentTabPanel value={assessmentTabValue} index={4}>
                  <Analysis
                    reportData={reportData}
                    saveReportData={saveReportData}
                    setSaveReportData={setSaveReportData}
                  />
                </CustomAssessmentTabPanel>
                {/* ) : (
                  <CustomAssessmentTabPanel value={assessmentTabValue} index={4}>
                    <Analysis2022 reportData={reportData} />
                  </CustomAssessmentTabPanel>
                )} */}

                {/* {(reportData?.projectInfoData?.NccType === '2019' ||
                  reportData?.projectInfoData?.NccType === '2022') && ( */}
                <CustomAssessmentTabPanel value={assessmentTabValue} index={5}>
                  <Analysis2
                    reportData={reportData}
                    saveReportData={saveReportData}
                    setSaveReportData={setSaveReportData}
                  />
                </CustomAssessmentTabPanel>
                {/* )} */}

                <CustomAssessmentTabPanel value={assessmentTabValue} index={6}>
                  <Specifications
                    reportData={reportData}
                    saveReportData={saveReportData}
                    setSaveReportData={setSaveReportData}
                    invalidFields={invalidFields}
                  />
                </CustomAssessmentTabPanel>

                <CustomAssessmentTabPanel value={assessmentTabValue} index={7}>
                  <ArtificialLighting
                    reportData={reportData}
                    saveReportData={saveReportData}
                    setSaveReportData={setSaveReportData}
                    invalidFields={invalidFields}
                  />
                </CustomAssessmentTabPanel>

                {reportData?.projectInfoData?.NccType === '2022' && (
                  <>
                    <CustomAssessmentTabPanel value={assessmentTabValue} index={8}>
                      <Regulations1 reportData={reportData} />
                    </CustomAssessmentTabPanel>

                    <CustomAssessmentTabPanel value={assessmentTabValue} index={9}>
                      <Regulations2 reportData={reportData} />
                    </CustomAssessmentTabPanel>
                  </>
                )}

                {reportData?.projectInfoData?.NccType === '2019' && (
                  <>
                    <CustomAssessmentTabPanel value={assessmentTabValue} index={8}>
                      <Regulations12019 reportData={reportData} />
                    </CustomAssessmentTabPanel>

                    <CustomAssessmentTabPanel value={assessmentTabValue} index={9}>
                      <Regulations22019 reportData={reportData} saveReportData={saveReportData} />
                    </CustomAssessmentTabPanel>
                  </>
                )}

                <CustomAssessmentTabPanel value={assessmentTabValue} index={10}>
                  <Regulations reportData={reportData} />
                </CustomAssessmentTabPanel>
              </>
            )}
          </CustomTabPanel>
        </Box>
      </Container>
    </Container>
  );
}
