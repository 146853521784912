import Iconify from 'src/components/iconify';
import {
  // CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
// import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import DateRangePicker, { useDateRangePicker } from 'src/components/date-range-picker';
import { useNavigate } from 'react-router';
import axios from 'src/utils/axios';
import _mock, { randomInArray } from 'src/_mock';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { fNumber } from 'src/utils/formatNumber';
import Scrollbar from 'src/components/scrollbar';
// import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useAuthContext } from 'src/auth/useAuthContext';
import Tooltip from '@mui/material/Tooltip';
// import { fDate } from 'src/utils/formatTime';
import {
  List,
  Paper,
  Avatar,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
  Grid,
  Container,
  // Stack,
  Card,
  // CardContent,
  Typography,
  // CircularProgress,
  // FormControlLabel,
  // Checkbox,
  Button,
  TextField,
  // IconButton,
  // Backdrop,
  // RadioGroup,
  // Radio,
  Dialog,
  DialogTitle,
  InputAdornment,
  Box,
  // FormLabel,
  // Select,
  // MenuItem,
  // Switch,
  // Breadcrumbs,
  // Link,
  ButtonProps,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  styled,
  // useTheme
} from '@mui/material/styles';
import { useSnackbar } from 'src/components/snackbar';
import DataGridCustom from './DataGridCustom';

interface JobInfo {
  id: string;
  JobRecordID: number;
  JobNumber: string;
  JobAddJobcode: string;
  JobSlaTime: string;
  JobSlaMsg: string;
  AWOH_VALID: string;
  Ncc_Type: string;
  EmailRecordID: number;
  JobEmailMsgID: number;
  JobEmailSubject: string;
  JobFloors: number;
  JobRequestorID: number;
  JobSiteAddr: string;
  JobAddrState: string;
  JobIsUrgent: string;
  JobCode: string;
  JobType: string;
  JobPrelimFinal: string;
  JobAssessorID: number;
  JobReprocessFlag: boolean;
  JobDwelling: string;
  JobStatus: string;
  JobStage: string;
  JobProcessedFlag: string;
  JobProcessedAt: string | null;
  JobCreatedAt: string;
  JobRating: number;
  JobPostAssessmentFlag: boolean;
  ReqName: string;
  UserFname: string;
  UserLname: string;
  UserEmail: string;
  LinkedJobs: boolean;
}

export default function Jobs() {
  // const [start, setStart] = useState<Date | null>(new Date());
  // const [end, setEnd] = useState<Date | null>(new Date());
  // const [open, setOpen] = useState(false);
  const [jobs, setJobs] = useState<JobInfo[]>([]);
  // const [assessorJobs, setAssessorJobs] = useState<JobInfo[]>([]);
  // const [jobStatusCounts, setJobStatusCounts] = useState<any>(null);
  const [assignedCounts, setAssignedCount] = useState<any>(null);
  const [assignedUrgentCounts, setAssignedUrgentCount] = useState<any>(null);
  const [assessmentStartedCount, setAssessmentStartedCount] = useState<any>(null);
  const [assessmentStartedUrgentCount, setAssessmentStartedUrgentCount] = useState<any>(null);
  const [completedCount, setCompletedCount] = useState<any>(null);
  const [completedUrgentCount, setCompletedUrgentCount] = useState<any>(null);
  const [onHoldCount, setOnHoldCount] = useState<any>(null);
  const [onHoldCountUrgentCount, setOnHoldUrgentCount] = useState<any>(null);
  // const [jobStatusCountByName, setJobStatusCountByname] = useState<number | null>(null);

  const [filteredData, setFilteredData] = useState<JobInfo[]>([]);
  // const [filteredActiveData, setFilteredActiveData] = useState<JobInfo[]>([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthContext();
  const [searchQuery, setSearchQuery] = useState('');
  const latestJobId = localStorage.getItem('latestJobId');
  const [latestProcessedJobId, setLatestProcessedJobId] = useState('');
  const [sendNotification, setSendNotificaton] = useState(false);
  const [info, setInfo] = useState('');
  // const them = useTheme();
  // const isDarkMode = them.palette.mode === 'dark';
  const [filterStatus, setFilterStatus] = useState(() => {
    if (user?.UserRole === 'Assessor') {
      return 'assessment started';
    }
    if (user?.UserRole === 'Workflow') {
      return 'workflow to action';
    }
    if (user?.UserRole === 'Management') {
      return 'workflow to action';
    }
    return 'assigned';
  });

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  const handleNotification = () => {
    setSendNotificaton(true);
  };
  const handleClose = () => {
    setSendNotificaton(false);
    setInfo('');
  };
  const handleInputChange = (event: any) => {
    setInfo(event.target.value);
  };

  const sendNotificationToWorkflow = () => {
    const payload = {
      message: info,
    };
    axios
      .post(`/core/sendNotificationToAllWorkflow`, payload)
      .then((response) => {
        // console.log('Response from backend:', response.data);
        enqueueSnackbar(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    handleClose();
    setInfo('');
  };
  const filteredJob = searchQuery
    ? filteredData.filter((job: any) => {
        const searchLower = searchQuery.toLowerCase();
        // const searchUpper = searchQuery.toUpperCase();

        let idMatch = false;
        let ReqNameMatch = false;
        let JobNumberMatch = false;
        let AssessornameMatch = false;
        let UserLnameMatch = false;
        let JobRatingMatch = false;
        let SiteAddress = false;

        if (filterStatus === 'urgent') {
          idMatch = job.id.toString().includes(searchLower);
          ReqNameMatch =
            job.JobIsUrgent.trim() !== '' && job.ReqName.toLowerCase().includes(searchLower);
          JobNumberMatch =
            job.JobIsUrgent.trim() !== '' && job.JobNumber.toLowerCase().includes(searchLower);
          AssessornameMatch =
            job.JobIsUrgent.trim() !== '' && job.UserFname.toLowerCase().includes(searchLower);
          UserLnameMatch =
            job.JobIsUrgent.trim() !== '' && job.UserLname.toLowerCase().includes(searchLower);
          JobRatingMatch =
            job.JobIsUrgent.trim() !== '' &&
            job.JobRating.toString().toLowerCase().includes(searchLower);
          SiteAddress =
            job.JobIsUrgent.trim() !== '' && job.JobSiteAddr.toLowerCase().includes(searchLower);
        } else {
          idMatch = job.id.toString().toLowerCase().includes(searchLower);
          ReqNameMatch = job.ReqName.toLowerCase().includes(searchLower);
          JobNumberMatch = job.JobNumber.toLowerCase().includes(searchLower);
          AssessornameMatch = job.UserFname.toLowerCase().includes(searchLower);
          UserLnameMatch = job.UserLname.toLowerCase().includes(searchLower);
          JobRatingMatch = job.JobRating.toString().toLowerCase().includes(searchLower);
          SiteAddress = job.JobSiteAddr.toLowerCase().includes(searchLower);
        }

        const searchdata =
          idMatch ||
          ReqNameMatch ||
          AssessornameMatch ||
          UserLnameMatch ||
          SiteAddress ||
          JobRatingMatch ||
          JobNumberMatch;

        return searchdata;
      })
    : filteredData;

  const activeStatuses = useMemo(
    () => [
      'new',
      'assigned',
      'assessment started',
      'workflow to action',
      'exception',
      // 'reassess initiated',
    ],
    []
  );
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const milliseconds = new Date().getMilliseconds();
  //       console.log(milliseconds);

  //       const response = await axios.get('/core/getAllJobs');
  //       // console.log('1 ', milliseconds - new Date().getMilliseconds());

  //       console.log('assessor:', response.data);
  //       const modifiedData = response.data.map(({ JobRecordID, ...job }: any) => ({
  //         id: JobRecordID,
  //         // JobCreatedAt: new Date(JobCreatedAt),
  //         ...job,
  //       }));
  //       // console.log('2 ', milliseconds - new Date().getMilliseconds());

  //       setJobs(modifiedData as JobInfo[]);

  //       if (user?.UserRole === 'Assessor') {
  //         // (item) => activeStatuses.includes(item.JobStatus)
  //         setFilteredData(
  //           modifiedData.filter(
  //             (item: any) =>
  //               activeStatuses.includes(item.JobStatus) && item.JobAssessorID === user?.ID
  //           ) as JobInfo[]
  //         );
  //       } else {
  //         setFilteredData(
  //           modifiedData.filter((item: any) => activeStatuses.includes(item.JobStatus)) as JobInfo[]
  //         );
  //       }
  //       // console.log('4 ', milliseconds - new Date().getMilliseconds());
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   // const fetchStatusCount = async () => {
  //   //   try {
  //   //     const response = await axios.get('/core/getJobStatusCount');
  //   //     setJobStatusCounts(response.data);
  //   //   } catch (error) {
  //   //     console.error(error);
  //   //   }
  //   // };

  //   fetchData();
  //   // fetchStatusCount();
  // }, [activeStatuses, user?.ID, user?.UserRole]);

  // const fetchData = useCallback(async () => {
  //   try {
  //     console.log('Page refreshed after 15 seconds');
  //     const response = await axios.get('/core/getAllJobs');

  //     const modifiedData = response.data.map(({ JobRecordID, ...job }: any) => ({
  //       id: JobRecordID,
  //       ...job,
  //     }));

  //     setJobs(modifiedData as JobInfo[]);
  //     if (filterStatus === 'all') {
  //       if (user?.UserRole === 'Assessor') {
  //         const filteredJobs = modifiedData.filter((item: any) => item.JobAssessorID === user?.ID);
  //         setFilteredData(filteredJobs);
  //         setLatestProcessedJobId(
  //           modifiedData.length > 0 ? modifiedData[modifiedData.length - 1].id : ''
  //         );
  //       } else {
  //         console.log('All jobs = ', modifiedData);
  //         setFilteredData(modifiedData);
  //         setLatestProcessedJobId(
  //           modifiedData.length > 0 ? modifiedData[modifiedData.length - 1].id : ''
  //         );
  //       }
  //     } else if (filterStatus === 'active') {
  //       if (user?.UserRole === 'Assessor') {
  //         const filteredJobs = modifiedData.filter(
  //           (item: any) =>
  //             activeStatuses.includes(item.JobStatus) && item.JobAssessorID === user?.ID
  //         );
  //         setFilteredData(filteredJobs);
  //         setLatestProcessedJobId(
  //           modifiedData.length > 0 ? modifiedData[modifiedData.length - 1].id : ''
  //         );
  //       } else {
  //         const filtered = modifiedData.filter((item: any) =>
  //           activeStatuses.includes(item.JobStatus)
  //         );
  //         setFilteredData(filtered);
  //         setLatestProcessedJobId(
  //           modifiedData.length > 0 ? modifiedData[modifiedData.length - 1].id : ''
  //         );
  //       }
  //     } else if (filterStatus === 'urgent') {
  //       const filtered = modifiedData.filter((item: any) => item.JobIsUrgent === filterStatus);
  //       setFilteredData(filtered);
  //       setLatestProcessedJobId(
  //         modifiedData.length > 0 ? modifiedData[modifiedData.length - 1].id : ''
  //       );
  //     } else if (filterStatus !== 'all') {
  //       if (user?.UserRole === 'Assessor') {
  //         const filteredJobs = modifiedData.filter(
  //           (item: any) => item.JobStatus === filterStatus && item.JobAssessorID === user?.ID
  //         );
  //         setFilteredData(filteredJobs);
  //         setLatestProcessedJobId(
  //           modifiedData.length > 0 ? modifiedData[modifiedData.length - 1].id : ''
  //         );
  //       } else {
  //         const filtered = modifiedData.filter((item: any) => item.JobStatus === filterStatus);
  //         console.log('Other statues = ', filtered);
  //         setFilteredData(filtered);
  //         setLatestProcessedJobId(
  //           modifiedData.length > 0 ? modifiedData[modifiedData.length - 1].id : ''
  //         );
  //       }
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, [activeStatuses, filterStatus, user?.ID, user?.UserRole]);

  const fetchData = useCallback(async () => {
    try {
      // debugger; // eslint-disable-line no-debugger
      console.log('Page refreshed after 15 seconds');
      console.log('user idd===', user?.ID);
      let response;
      let modifiedData: any[] = [];

      if (user?.UserRole === 'Assessor') {
        console.log('Role is assessor');
        response = await axios.get(`/core/getJobsByID?assessorId=${user?.ID}`);
        console.log('Assessors api calling');
        console.log('Assessors job = ', response.data);
      } else {
        response = await axios.get('/core/getAllJobs');
        console.log('Job data = ', response.data);
      }

      modifiedData = response.data.map(({ JobRecordID, ...job }: any) => ({
        id: JobRecordID,
        ...job,
      }));

      setJobs(modifiedData as JobInfo[]);
      if (filterStatus === 'all') {
        console.log('All jobs = ', modifiedData);
        setFilteredData(modifiedData);
        setLatestProcessedJobId(
          modifiedData.length > 0 ? modifiedData[modifiedData.length - 1].id : ''
        );
      } else if (filterStatus === 'active') {
        const filtered = modifiedData.filter((item: any) =>
          activeStatuses.includes(item.JobStatus)
        );
        setFilteredData(filtered);
        setLatestProcessedJobId(
          modifiedData.length > 0 ? modifiedData[modifiedData.length - 1].id : ''
        );
      } else if (filterStatus === 'urgent') {
        const filtered = modifiedData.filter((item: any) => item.JobIsUrgent === filterStatus && item.JobStatus !== 'marked as complete');
        setFilteredData(filtered);
        setLatestProcessedJobId(
          modifiedData.length > 0 ? modifiedData[modifiedData.length - 1].id : ''
        );
      } else if (filterStatus !== 'all') {
        const filtered = modifiedData.filter((item: any) => item.JobStatus === filterStatus);
        console.log('Other statues = ', filtered);
        setFilteredData(filtered);
        setLatestProcessedJobId(
          modifiedData.length > 0 ? modifiedData[modifiedData.length - 1].id : ''
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, [activeStatuses, filterStatus, user?.ID, user?.UserRole]);

  useEffect(() => {
    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 15000);

    return () => clearInterval(intervalId);
  }, [fetchData]);

  // useEffect(() => {
  //   if (user?.UserRole === 'Assessor') {
  //     setAssignedCount(
  //       jobs.filter((item) => item.JobStatus === 'assigned' && item.JobAssessorID === user?.ID)
  //         .length
  //     );
  //     setAssignedUrgentCount(
  //       jobs.filter(
  //         (item) =>
  //           item.JobStatus === 'assigned' &&
  //           item.JobIsUrgent === 'urgent' &&
  //           item.JobAssessorID === user?.ID
  //       ).length
  //     );
  //   } else {
  //     setAssignedCount(jobs.filter((item) => item.JobStatus === 'assigned').length);
  //     setAssignedUrgentCount(
  //       jobs.filter((item) => item.JobStatus === 'assigned' && item.JobIsUrgent === 'urgent').length
  //     );
  //   }

  //   if (user?.UserRole === 'Assessor') {
  //     setAssessmentStartedCount(
  //       jobs.filter(
  //         (item) => item.JobStatus === 'assessment started' && item.JobAssessorID === user?.ID
  //       ).length
  //     );
  //     setAssessmentStartedUrgentCount(
  //       jobs.filter(
  //         (item) =>
  //           item.JobStatus === 'assessment started' &&
  //           item.JobIsUrgent === 'urgent' &&
  //           item.JobAssessorID === user?.ID
  //       ).length
  //     );
  //   } else {
  //     setAssessmentStartedCount(
  //       jobs.filter((item) => item.JobStatus === 'assessment started').length
  //     );
  //     setAssessmentStartedUrgentCount(
  //       jobs.filter(
  //         (item) => item.JobStatus === 'assessment started' && item.JobIsUrgent === 'urgent'
  //       ).length
  //     );
  //   }

  //   if (user?.UserRole === 'Assessor') {
  //     setCompletedCount(
  //       jobs.filter((item) => item.JobStatus === 'complete' && item.JobAssessorID === user?.ID)
  //         .length
  //     );
  //     setCompletedUrgentCount(
  //       jobs.filter(
  //         (item) =>
  //           item.JobStatus === 'complete' &&
  //           item.JobIsUrgent === 'urgent' &&
  //           item.JobAssessorID === user?.ID
  //       ).length
  //     );
  //   } else {
  //     setCompletedCount(jobs.filter((item) => item.JobStatus === 'complete').length);
  //     setCompletedUrgentCount(
  //       jobs.filter((item) => item.JobStatus === 'complete' && item.JobIsUrgent === 'urgent').length
  //     );
  //   }

  //   if (user?.UserRole === 'Assessor') {
  //     setOnHoldCount(
  //       jobs.filter((item) => item.JobStatus === 'on hold' && item.JobAssessorID === user?.ID)
  //         .length
  //     );
  //     setOnHoldUrgentCount(
  //       jobs.filter(
  //         (item) =>
  //           item.JobStatus === 'on hold' &&
  //           item.JobIsUrgent === 'urgent' &&
  //           item.JobAssessorID === user?.ID
  //       ).length
  //     );
  //   } else {
  //     setOnHoldCount(jobs.filter((item) => item.JobStatus === 'on hold').length);
  //     setOnHoldUrgentCount(
  //       jobs.filter((item) => item.JobStatus === 'on hold' && item.JobIsUrgent === 'urgent').length
  //     );
  //   }
  // }, [jobs, user?.ID, user?.UserRole]);

  useEffect(() => {
    const refreshCounts = () => {
      if (user?.UserRole === 'Assessor') {
        setAssignedCount(
          jobs.filter((item) => item.JobStatus === 'assigned' && item.JobAssessorID === user?.ID)
            .length
        );
        setAssignedUrgentCount(
          jobs.filter(
            (item) =>
              item.JobStatus === 'assigned' &&
              item.JobIsUrgent === 'urgent' &&
              item.JobAssessorID === user?.ID
          ).length
        );
      } else {
        setAssignedCount(jobs.filter((item) => item.JobStatus === 'assigned').length);
        setAssignedUrgentCount(
          jobs.filter((item) => item.JobStatus === 'assigned' && item.JobIsUrgent === 'urgent')
            .length
        );
      }

      if (user?.UserRole === 'Assessor') {
        setAssessmentStartedCount(
          jobs.filter(
            (item) => item.JobStatus === 'assessment started' && item.JobAssessorID === user?.ID
          ).length
        );
        setAssessmentStartedUrgentCount(
          jobs.filter(
            (item) =>
              item.JobStatus === 'assessment started' &&
              item.JobIsUrgent === 'urgent' &&
              item.JobAssessorID === user?.ID
          ).length
        );
      } else {
        setAssessmentStartedCount(
          jobs.filter((item) => item.JobStatus === 'assessment started').length
        );
        setAssessmentStartedUrgentCount(
          jobs.filter(
            (item) => item.JobStatus === 'assessment started' && item.JobIsUrgent === 'urgent'
          ).length
        );
      }

      if (user?.UserRole === 'Assessor') {
        setCompletedCount(
          jobs.filter(
            (item) => item.JobStatus === 'marked as complete' && item.JobAssessorID === user?.ID
          ).length
        );
        setCompletedUrgentCount(
          jobs.filter(
            (item) =>
              item.JobStatus === 'marked as complete' &&
              item.JobIsUrgent === 'urgent' &&
              item.JobAssessorID === user?.ID
          ).length
        );
      } else {
        setCompletedCount(jobs.filter((item) => item.JobStatus === 'marked as complete').length);
        setCompletedUrgentCount(
          jobs.filter(
            (item) => item.JobStatus === 'marked as complete' && item.JobIsUrgent === 'urgent'
          ).length
        );
      }

      if (user?.UserRole === 'Assessor') {
        setOnHoldCount(
          jobs.filter((item) => item.JobStatus === 'on hold' && item.JobAssessorID === user?.ID)
            .length
        );
        setOnHoldUrgentCount(
          jobs.filter(
            (item) =>
              item.JobStatus === 'on hold' &&
              item.JobIsUrgent === 'urgent' &&
              item.JobAssessorID === user?.ID
          ).length
        );
      } else {
        setOnHoldCount(jobs.filter((item) => item.JobStatus === 'on hold').length);
        setOnHoldUrgentCount(
          jobs.filter((item) => item.JobStatus === 'on hold' && item.JobIsUrgent === 'urgent')
            .length
        );
      }
    };
    refreshCounts();
    const intervalId = setInterval(() => {
      refreshCounts();
    }, 30000);
    return () => clearInterval(intervalId);
  }, [jobs, user?.ID, user?.UserRole]);

  const StyledListContainer = styled(Paper)(({ theme }) => ({
    width: '100%',
    border: `solid 1px ${theme.palette.divider}`,
  }));

  // const card: CSSProperties = {
  //   // backgroundColor:isDarkMode ? theme.palette.grey[600] : theme.palette.grey[400],
  //   display: 'flex',
  //   borderRadius: 0,
  //   flexDirection: 'column',
  //   alignItems: 'center',
  // };
  // const count: CSSProperties = {
  //   marginTop: 1,
  //   fontSize: '28px',
  //   fontWeight: 'bold',
  // };
  // const label: CSSProperties = {
  //   fontSize: '16px',
  //   marginTop: '8px',
  // };
  // const [rowid, setrowid] = useState('');

  const handleView = (value: string, edit: boolean, report: boolean) => {
    if (report) {
      navigate(`/viewJobReport?id=${value}`);
    } else {
      navigate(`/viewJobs?id=${value}&edit=${edit}`);
    }
  };

  const goToAddJobPage = () => {
    navigate('/addjob');
  };
  const goToBasixRefundCertificate = () => {
    navigate('/refund jobs');
  };

  // const handleClose = () => {};
  // const options = [
  //   { value: 'option 1', label: 'New' },
  //   { value: 'option 2', label: 'Existing' },
  // ];

  // const [filterStatus, setFilterStatus] = useState('all');
  // const [filteredData, setFilteredData] = useState(_dataGrid);

  const handleFilterChange = (status: string) => {
    setFilterStatus(status);
    setSearchQuery('');
    if (status === 'all') {
      if (user?.UserRole === 'Assessor') {
        const filteredJobs = jobs.filter((item: any) => item.JobAssessorID === user?.ID);
        setFilteredData(filteredJobs);
        // console.log('Filtered jobs = ', filteredJobs);
      } else {
        // console.log('All jobs = ', jobs);
        setFilteredData(jobs);
        // console.log('All filter jobs = ', filteredData);
      }
    } else if (status === 'active') {
      if (user?.UserRole === 'Assessor') {
        const filteredJobs = jobs.filter(
          (item: any) => activeStatuses.includes(item.JobStatus) && item.JobAssessorID === user?.ID
        );
        setFilteredData(filteredJobs);
        // console.log('Filtered jobs assessor = ', filteredData);
      } else {
        console.log('in active job else part = ');
        const filtered = jobs.filter((item) => activeStatuses.includes(item.JobStatus));
        // console.log('1 = ', filtered);
        setFilteredData(filtered);
        // console.log('2 = ', filteredData);
      }
    } else if (status === 'urgent') {
      if (user?.UserRole === 'Assessor') {
        const filtered = jobs.filter(
          (item) => item.JobIsUrgent === status && item.JobAssessorID === user?.ID
        );
        setFilteredData(filtered);
      } else {
        const filtered = jobs.filter((item) => item.JobIsUrgent === status);
        setFilteredData(filtered);
      }
    } else if (status !== 'all') {
      if (user?.UserRole === 'Assessor') {
        const filteredJobs = jobs.filter(
          (item: any) => item.JobStatus === status && item.JobAssessorID === user?.ID
        );
        setFilteredData(filteredJobs);
      } else {
        const filtered = jobs.filter((item) => item.JobStatus === status);
        // console.log('Other statues = ', filtered);
        setFilteredData(filtered);
        // console.log('Other statuses filter data = ', filteredData);
      }
    }
  };
  const getStatusCounts = (status: string) => {
    let statusCounts = 0;
    if (status === 'all') {
      if (user?.UserRole === 'Assessor') {
        const filteredJobs = jobs.filter((item: any) => item.JobAssessorID === user?.ID);
        statusCounts = filteredJobs.length;
        // console.log('Filtered jobs = ', filteredJobs);
      } else {
        statusCounts = jobs.length;
      }
    } else if (status === 'urgent') {
      jobs.forEach((item) => {
        if (item.JobIsUrgent === status && item.JobStatus !== "marked as complete") {
          statusCounts += 1;
        }
      });
    } else if (status === 'active') {
      if (user?.UserRole === 'Assessor') {
        const filteredJobs = jobs.filter(
          (item: any) => activeStatuses.includes(item.JobStatus) && item.JobAssessorID === user?.ID
        );
        statusCounts = filteredJobs.length;
        // console.log('Assessor active jobs = ', statusCounts);
      } else {
        const filtered = jobs.filter((item) => activeStatuses.includes(item.JobStatus));
        statusCounts = filtered.length;
      }
    } else if (user?.UserRole === 'Assessor') {
      const filteredJobs = jobs.filter(
        (item: any) => item.JobStatus === status && item.JobAssessorID === user?.ID
      );
      statusCounts = filteredJobs.length;
    } else {
      const filteredJobs = jobs.filter((item: any) => item.JobStatus === status);
      statusCounts = filteredJobs.length;
    }

    return statusCounts;
  };
  // const {
  //   startDate,
  //   endDate,
  //   onChangeStartDate,
  //   onChangeEndDate,
  //   open: openPicker,
  //   onOpen: onOpenPicker,
  //   onClose: onClosePicker,
  //   onReset: onResetPicker,
  //   isSelected: isSelectedValuePicker,
  //   isError,
  //   shortLabel,
  // } = useDateRangePicker(new Date(), new Date());

  // const handleChangeStartDate = (newValue: Date | null) => {
  //   onChangeStartDate(newValue);
  // };

  // const handleChangeEndDate = (newValue: Date | null) => {
  //   onChangeEndDate(newValue);
  // };

  // const requestJob = async () => {
  //   try {
  //     await axios
  //       .get(`/core/requestByAssessor?ID=${user?.ID}`)
  //       .then((response) => {
  //         console.log('Request by assessor = ', response.data);
  //         // if (response.data === 'Success') {
  //         if (response.data === 'Success') {
  //           enqueueSnackbar('This request has been successful and scheduled.');
  //         } else {
  //           enqueueSnackbar(response.data, { variant: 'error' });
  //         }
  //       })
  //       .catch((error) => {
  //         // console.log('Request by assessor catch= ');
  //         enqueueSnackbar(error, { variant: 'error' });
  //       });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const requestJob = async () => {
    try {
      console.log('Request by assessor in jobs = ');
      await axios
        .get(`/core/requestByAssessor?ID=${user?.ID}`)
        .then((response) => {
          console.log('Request by assessor in jobs = ', response.data);
          enqueueSnackbar(response.data);
          // if (response.data === 'Success') {
          //   enqueueSnackbar('This request was successful and job has been assigned.');
          // }
        })
        .catch((error) => {
          console.log('Request by assessor catch= ');
          enqueueSnackbar(error, { variant: 'error' });
        });
    } catch (error) {
      console.error('Error = ', error);
    }
  };

  // const requestJob = async () => {
  //   try {
  //     const response = await axios.get(`/core/requestByAssessor?ID=${user?.ID}`);
  //     const responseData = response.data;
  //     console.log('Request by assessor =', responseData);

  //     if (responseData.error) {
  //       enqueueSnackbar(responseData.error, { variant: 'error' });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const [view, setview] = useState('');
  // const [screen, setscreen] = useState(null);
  return (
    <Container sx={{ maxWidth: '1860px !important', marginTop: 2 }}>
      {/* <Dialog
                    fullWidth
                    maxWidth='lg'
                    open={open}
                    onClose={handleClose}
                >
                       <ViewJobs handleClose={handleClose} data={_dataGrid.filter((row)=> row.id === rowid)[0]}/>

            </Dialog> */}
      <Grid container spacing={3}>
        {/* <Grid sx={{ marginLeft: 10, marginTop: 1 }}>
          <Typography variant="h4"> Today</Typography>
        </Grid> */}
        <Grid container direction="row" spacing={1} sx={{ margin: 1, marginLeft: 10 }}>
          <Grid item xs={12} sm={6} md={user?.UserRole === 'Assessor' ? 3.9 : 2.9}>
            <Card
              sx={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                p: 7,
                height: '80%',
                width: '100%',
                minHeight: '170px',
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle2">
                  Assessment Started /
                  <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                </Typography>
                {/* <Typography variant="h3">
                  {fNumber(jobStatusCounts[0].TotalCount)} /{' '}
                  <span style={{ fontSize: '20px', color: '#ff5630' }}>
                    {jobStatusCounts[0].UrgentCount}
                  </span>
                </Typography> */}

                {/* {jobStatusCounts !== null && jobStatusCounts.length > 0 ? (
                  <Typography variant="h3">
                    {fNumber(jobStatusCounts[0].TotalCount)} /{' '}
                    <span style={{ fontSize: '20px', color: '#ff5630' }}>
                      {jobStatusCounts[0].UrgentCount}
                    </span>
                  </Typography>
                ) : (
                  <Typography variant="h6">Loading...</Typography>
                )} */}

                {assessmentStartedCount !== null ? (
                  <Typography variant="h3">
                    {fNumber(assessmentStartedCount)} /{' '}
                    <span style={{ fontSize: '30px', color: '#ff5630' }}>
                      {assessmentStartedUrgentCount}
                    </span>
                  </Typography>
                ) : (
                  <Typography variant="h6">Loading...</Typography>
                )}
              </Box>
              {/* <Iconify icon="carbon:in-progress" width={24} /> */}
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={user?.UserRole === 'Assessor' ? 3.9 : 2.9}>
            <Card
              sx={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                p: 7,
                height: '80%',
                width: '100%',
                minHeight: '170px',
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle1">
                  Assigned (Assessment Not Started)/
                  <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                </Typography>
                {/* {jobStatusCounts !== null && jobStatusCounts.length > 0 ? (
                  <Typography variant="h3">
                    {fNumber(jobStatusCounts[1].TotalCount)} /{' '}
                    <span style={{ fontSize: '20px', color: '#ff5630' }}>
                      {jobStatusCounts[1].UrgentCount}
                    </span>
                  </Typography>
                ) : (
                  <Typography variant="h6">Loading...</Typography>
                )} */}
                {assignedCounts !== null ? (
                  <Typography variant="h3">
                    {fNumber(assignedCounts)} /{' '}
                    <span style={{ fontSize: '30px', color: '#ff5630' }}>
                      {assignedUrgentCounts}
                    </span>
                  </Typography>
                ) : (
                  <Typography variant="h6">Loading...</Typography>
                )}
              </Box>
              {/* <Iconify icon="ic:baseline-assessment" width={24} /> */}
            </Card>{' '}
          </Grid>

          <Grid item xs={12} sm={6} md={user?.UserRole === 'Assessor' ? 3.8 : 2.9}>
            <Card
              sx={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                p: 7,
                height: '70%',
                width: '100%',
                minHeight: '170px',
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle1">
                  On Hold /<span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                </Typography>
                {/* {jobStatusCounts !== null && jobStatusCounts.length > 0 ? (
                  <Typography variant="h3">
                    {fNumber(jobStatusCounts[3].TotalCount)} /{' '}
                    <span style={{ fontSize: '20px', color: '#ff5630' }}>
                      {jobStatusCounts[3].UrgentCount}
                    </span>
                  </Typography>
                ) : (
                  <Typography variant="h6">Loading...</Typography>
                )} */}
                {onHoldCount !== null ? (
                  <Typography variant="h3">
                    {fNumber(onHoldCount)} /{' '}
                    <span style={{ fontSize: '30px', color: '#ff5630' }}>
                      {onHoldCountUrgentCount}
                    </span>
                  </Typography>
                ) : (
                  <Typography variant="h6">Loading...</Typography>
                )}
              </Box>
              {/* <Iconify icon="pajamas:status-paused" width={24} /> */}
            </Card>
          </Grid>
          {user?.UserRole !== 'Assessor' && (
            <Grid item xs={12} sm={6} md={2.9}>
              <Card
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                  p: 7,
                  height: '80%',
                  width: '100%',
                  minHeight: '170px',
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="subtitle1">
                    Marked as Complete /
                    <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                  </Typography>
                  {/* {jobStatusCounts !== null && jobStatusCounts.length > 0 ? (
                  <Typography variant="h3">
                    {fNumber(jobStatusCounts[2].TotalCount)} /{' '}
                    <span style={{ fontSize: '20px', color: '#ff5630' }}>
                      {jobStatusCounts[2].UrgentCount}
                    </span>
                  </Typography>
                ) : (
                  <Typography variant="h6">Loading...</Typography>
                )} */}

                  {completedCount !== null ? (
                    <Typography variant="h3">
                      {fNumber(completedCount)} /{' '}
                      <span style={{ fontSize: '30px', color: '#ff5630' }}>
                        {completedUrgentCount}
                      </span>
                    </Typography>
                  ) : (
                    <Typography variant="h6">Loading...</Typography>
                  )}
                </Box>
                {/* <Iconify icon="carbon:task-complete" width={24} /> */}
              </Card>{' '}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={4} sx={{ marginBottom: '10px', marginTop: '-50px' }}>
        <>
          <Grid item xs={12} sm={12} md={2.5}>
            <StyledListContainer
              sx={
                user?.UserRole === 'Assessor'
                  ? {
                      height: '250px',
                      minWidth: '250px',
                      marginTop: '30px',
                      borderRadius: 0,
                      width: '280px',
                      marginRight: '10px',
                    }
                  : {
                      height: '780px',
                      minWidth: '250px',
                      marginTop: '30px',
                      borderRadius: 0,
                      width: '280px',
                      marginRight: '10px',
                    }
              }
            >
              {/* <Scrollbar> */}
              <List>
                {/* ---- */}
                {user?.UserRole !== 'Assessor' && (
                  <ListItemButton
                    selected={filterStatus === 'workflow to action'}
                    onClick={() => {
                      handleFilterChange('workflow to action');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="ic:baseline-assessment" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Workflow To Action"
                      secondary={`count:${getStatusCounts('workflow to action')}`}
                    />
                  </ListItemButton>
                )}

                {user?.UserRole !== 'Assessor' && (
                  <ListItemButton
                    selected={filterStatus === 'pending validation'}
                    onClick={() => {
                      handleFilterChange('pending validation');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="carbon:task-complete" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Pending Validation / Recheck"
                      secondary={`count:${getStatusCounts('pending validation')}`}
                    />
                  </ListItemButton>
                )}

                {user?.UserRole !== 'Assessor' && (
                  <ListItemButton
                    sx={{
                      color: '#D53343',
                      '&.Mui-selected': {
                        backgroundColor: '#D53343',
                        color: 'white',
                      },
                    }}
                    selected={filterStatus === 'exception'}
                    onClick={() => {
                      handleFilterChange('exception');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="ant-design:exception-outlined" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Exception"
                      secondary={`count:${getStatusCounts('exception')}`}
                    />
                  </ListItemButton>
                )}

                {user?.UserRole !== 'Assessor' && (
                  <ListItemButton
                    selected={filterStatus === 'new'}
                    onClick={() => {
                      handleFilterChange('new');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="clarity:new-solid" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="New" secondary={`count:${getStatusCounts('new')}`} />
                  </ListItemButton>
                )}

                {user?.UserRole !== 'Assessor' && (
                  <ListItemButton
                    selected={filterStatus === 'urgent'}
                    onClick={() => {
                      handleFilterChange('urgent');
                    }}
                    sx={{
                      color: '#EDA565',
                      '&.Mui-selected': {
                        backgroundColor: '#F9B572',
                        color: 'white',
                      },
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="fluent:alert-urgent-24-filled" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Urgent"
                      secondary={`count:${getStatusCounts('urgent')}`}
                    />
                  </ListItemButton>
                )}

                {user?.UserRole !== 'Assessor' && (
                  <ListItemButton
                    selected={filterStatus === 'all'}
                    onClick={() => {
                      handleFilterChange('all');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="material-symbols:all-match-sharp" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="All" secondary={`count:${getStatusCounts('all')}`} />
                  </ListItemButton>
                )}
                {user?.UserRole !== 'Assessor' && (
                  <ListItemButton
                    selected={filterStatus === 'active'}
                    onClick={() => {
                      handleFilterChange('active');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="material-symbols:all-match-sharp" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Active Jobs"
                      secondary={`count:${getStatusCounts('active')}`}
                    />
                  </ListItemButton>
                )}

                {/* <ListItemButton
                    selected={filterStatus === 'in progress'}
                    onClick={() => {
                      handleFilterChange('in progress');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="carbon:in-progress" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="In Progress"
                      secondary={`count:${getStatusCounts('in progress')}`}
                    />
                  </ListItemButton> */}

                <ListItemButton
                  selected={filterStatus === 'assigned'}
                  onClick={() => {
                    handleFilterChange('assigned');
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <Iconify icon="mdi:assignment" width={24} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Assigned"
                    secondary={`count:${getStatusCounts('assigned')}`}
                  />
                </ListItemButton>
                <ListItemButton
                  selected={filterStatus === 'assessment started'}
                  onClick={() => {
                    handleFilterChange('assessment started');
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <Iconify icon="material-symbols:all-match-sharp" width={24} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Assessment Started"
                    secondary={`count:${getStatusCounts('assessment started')}`}
                  />
                </ListItemButton>

                {user?.UserRole !== 'Assessor' && (
                  <ListItemButton
                    selected={filterStatus === 'marked as complete'}
                    onClick={() => {
                      handleFilterChange('marked as complete');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="carbon:task-complete" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Marked as Complete"
                      secondary={`count:${getStatusCounts('marked as complete')}`}
                    />
                  </ListItemButton>
                )}
                {/* 
                  <ListItemButton
                    selected={filterStatus === 'reassess initiated'}
                    onClick={() => {
                      handleFilterChange('reassess initiated');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="material-symbols:all-match-sharp" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Reassess Initiated"
                      secondary={`count:${getStatusCounts('reassess initiated')}`}
                    />
                  </ListItemButton>
                  <ListItemButton
                    selected={filterStatus === 'reassess started'}
                    onClick={() => {
                      handleFilterChange('reassess started');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="material-symbols:all-match-sharp" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Reassess Started"
                      secondary={`count:${getStatusCounts('reassess started')}`}
                    />
                  </ListItemButton>

                  <ListItemButton
                    selected={filterStatus === 'reassess completed'}
                    onClick={() => {
                      handleFilterChange('reassess completed');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="material-symbols:all-match-sharp" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Reassess Completed"
                      secondary={`count:${getStatusCounts('reassess completed')}`}
                    />
                  </ListItemButton> */}

                <ListItemButton
                  selected={filterStatus === 'on hold'}
                  onClick={() => {
                    handleFilterChange('on hold');
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <Iconify icon="pajamas:status-paused" width={24} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="On Hold"
                    secondary={`count:${getStatusCounts('on hold')}`}
                  />
                </ListItemButton>
                {user?.UserRole !== 'Assessor' && (
                  <ListItemButton
                    selected={filterStatus === 'cancelled'}
                    onClick={() => {
                      handleFilterChange('cancelled');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="pajamas:status-cancelled" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Cancelled"
                      secondary={`count:${getStatusCounts('cancelled')}`}
                    />
                  </ListItemButton>
                )}
              </List>
              {/* </Scrollbar> */}
            </StyledListContainer>
          </Grid>
          <Grid
            direction="column"
            item
            xs={12}
            sm={12}
            md={9.5}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '680px',
              width: '680px',
              alignItems: 'start',
            }}
          >
            <Grid item xs={2} sm={2} md={1} spacing={3}>
              {/* <Grid container direction="row"  > */}
              <TextField
                variant="outlined"
                size="small"
                sx={{ width: '250px', marginTop: 1, height: '50px' }}
                placeholder="Search Job"
                type="search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="bi:search" width={24} />
                    </InputAdornment>
                  ),
                }}
                value={searchQuery}
                onChange={handleSearchChange}
                title="Search will be applicable for JobId, jobNumber, SiteAddress, Rating, Requestor FirstName and AssessorName"
              />
              {/* <Button
                variant="soft"
                size="medium"
                sx={{ marginLeft: 1, marginTop: 1, height: '40px' }}
              >
                search
              </Button> */}
              {/* <Button
                variant="soft"
                size="medium"
                color="secondary"
                sx={{ marginLeft: 1, marginTop: 1, height: '40px' }}
              >
                Reset{' '}
              </Button> */}
              {/* <FormControlLabel
                control={<Switch defaultChecked sx={{ marginLeft: 1 }} />}
                label="Case Sensitive"
              /> */}
              {/* <FileFilterButton
                isSelected={!!isSelectedValuePicker}
                startIcon={<Iconify icon="eva:calendar-fill" />}
                onClick={onOpenPicker}
              >
                {isSelectedValuePicker ? shortLabel : 'Select Date'}
              </FileFilterButton>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  variant="calendar"
                  startDate={startDate}
                  endDate={endDate}
                  onChangeStartDate={handleChangeStartDate}
                  onChangeEndDate={handleChangeEndDate}
                  open={openPicker}
                  onClose={onClosePicker}
                  isSelected={isSelectedValuePicker}
                  isError={isError}
                />
              </LocalizationProvider> */}
              {/* {user?.UserRole === 'Assessor' && (
                <>
                  <Box
                    sx={{
                      float: 'right',
                      marginLeft: '20px',
                      marginTop: '10px',
                      width: '180px',
                      height: '34px',
                      display: 'flex',
                      // color: 'black',
                      alignItems: 'center',
                      justifyContent: 'center',
                      // backgroundColor: '#f5f5f5',
                      // border: '1px solid white',
                      border: '1px solid ',
                      borderRadius: '9px',
                      fontSize: '13.4px',
                      fontWeight: 'bold',
                    }}
                  >
                    Latest Added Job ID - {latestProcessedJobId}
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={requestJob}
                    // sx={{ marginLeft: 1, height: '35px' }}
                    sx={{ marginLeft: '20px', marginTop: '10px' }}
                  >
                    Request Job
                  </Button>
                </>
              )} */}

              <Typography
                variant="body2"
                sx={{
                  float: 'right',
                  marginLeft: '10px',
                  color: 'red',
                  marginTop: '15px',
                  fontWeight: 900,
                }}
              >
                *Red indicates Urgent jobs
              </Typography>

              {user?.UserRole === 'Assessor' && (
                <>
                  {/* <Box
                    sx={{
                      float: 'right',
                      marginLeft: '20px',
                      marginTop: '10px',
                      width: '180px',
                      height: '34px',
                      display: 'flex',
                      // color: 'black',
                      alignItems: 'center',
                      justifyContent: 'center',
                      // backgroundColor: '#f5f5f5',
                      // border: '1px solid white',
                      border: '1px solid ',
                      borderRadius: '9px',
                      fontSize: '13.4px',
                      fontWeight: 'bold',
                    }}
                  >
                    Latest Added Job ID - {latestProcessedJobId}
                  </Box> */}
                  {/* <Tooltip
                    title="This button provides feature to Send A Notification To Workflow Team"
                    placement="top-end"
                  >
                    <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      onClick={handleNotification}
                      sx={{ float: 'right', marginLeft: '30px', marginTop: '10px' }}
                    >
                      Notify Workflow Team
                    </Button>
                  </Tooltip> */}
                  <Tooltip
                    title="This button provides feature to Request for a new Job."
                    placement="top-end"
                  >
                    <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      onClick={requestJob}
                      sx={{ float: 'right', marginLeft: '30px', marginTop: '10px' }}
                    >
                      Request Job
                    </Button>
                  </Tooltip>
                </>
              )}
              {user?.UserRole !== 'Assessor' && (
                <>
                  <Box
                    sx={{
                      float: 'right',
                      marginLeft: '20px',
                      marginTop: '10px',
                      width: '300px',
                      height: '34px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px solid ',
                      borderRadius: '9px',
                      fontSize: '13.4px',
                      fontWeight: 'bold',
                    }}
                  >
                    Latest Processed Job ID - {latestProcessedJobId}
                  </Box>
                  {/* <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={goToBasixRefundCertificate}
                    sx={{ float: 'right', marginLeft: '10px', marginTop: '10px' }}
                  >
                    Basix Refund Certificate
                  </Button> */}
                  <Tooltip
                    title="Click button to see Basix Refund Certificate information."
                    placement="top-end"
                  >
                    <span>
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        onClick={goToBasixRefundCertificate}
                        sx={{ float: 'right', marginLeft: '10px', marginTop: '10px' }}
                      >
                        Basix Refund Certificate
                      </Button>
                    </span>
                  </Tooltip>

                  {/* <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={goToAddJobPage}
                    sx={{ float: 'right', marginLeft: '20px', marginTop: '10px' }}
                  >
                    Add Job
                  </Button> */}
                  <Tooltip
                    title="This button provides feature to Create a job manually."
                    placement="top"
                  >
                    <span>
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        onClick={goToAddJobPage}
                        sx={{ float: 'right', marginLeft: '20px', marginTop: '10px' }}
                      >
                        Add Job
                      </Button>
                    </span>
                  </Tooltip>
                </>
              )}
            </Grid>
            <Grid container direction="row" sx={{ height: '550px' }}>
              {filteredJob && (
                <DataGridCustom
                  data={filteredJob}
                  sendData={(value, edit, report) => {
                    handleView(value, edit, report);
                  }}
                />
              )}
            </Grid>
          </Grid>
        </>
      </Grid>
      <Dialog open={sendNotification} onClose={handleClose} fullWidth>
        <DialogTitle>Notify To Workflow Team</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="info-input"
            label="Notification"
            type="text"
            fullWidth
            value={info}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={sendNotificationToWorkflow} color="primary" disabled={!info.trim()}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

const _dataGrid = [...Array(40)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  email: _mock.email(index),
  lastLogin: _mock.time(index),
  occupency: _mock.number.percent(index),
  rating: _mock.number.rating(index),
  status: randomInArray([
    'Assigned',
    'In Progress',
    'Marked as Complete',
    'Pending Validation',
    // 'Reassess Initiated',
    // 'Reassess Started',
    // 'Reasses Completed',
    'Workflow to Action',
    'On Hold',
    'Urgent Jobs',
    'Exception',
    'New',
    'Cancelled',
  ]),
  jobType: randomInArray(['New', 'Existing']),
  firstName: `${_mock.name.lastName(index)}  ${_mock.name.firstName(index)}`,
  lastName: _mock.name.firstName(index),
  createdDate: _mock.time(index),
}));

interface Props extends ButtonProps {
  children?: ReactNode;
  isSelected: boolean;
}

function FileFilterButton({ children, isSelected, ...other }: Props) {
  return (
    <Button
      variant="soft"
      color="inherit"
      sx={{
        textTransform: 'unset',
        color: 'text.secondary',
        width: { xs: 1, md: 'auto' },
        justifyContent: 'flex-start',
        fontWeight: 'fontWeightMedium',
        ...(isSelected && {
          color: 'text.primary',
        }),
      }}
      {...other}
    >
      {children}

      <Box sx={{ flexGrow: 1 }} />
    </Button>
  );
}
