import {
  Button,
  FormControlLabel,
  Grid,
  // Stack,
  Typography,
  Checkbox,
  Select,
  MenuItem,
  Box,
  IconButton,
  Divider,
} from '@mui/material';
import { useSnackbar } from 'src/components/snackbar';
// import _mock from 'src/_mock/_mock';
import { useEffect, useState } from 'react';
import { CloseIcon } from 'src/theme/overrides/CustomIcons';
import axios from 'src/utils/axios';
import { useNavigate } from 'react-router-dom';

import { useAuthContext } from 'src/auth/useAuthContext';

type Requestors = {
  ID: number;
  ReqName: string;
  JobRequestorID: string;
  ReqSubloc: string;
  ReqCreatedDatetime: string;
  ReqEmailDomain: string;
  ReqContactPhone: string;
  ReqStatus: string;
  IsDefaultFinal: string;
  IsPrelimFinalMentionedSubject: string;
  ReqType: string;
  ReqJobTypes: string;
  ReqEmailResponse: string;
  PeopleListed: string;
  Notes: string;
};

export default function AddAssessorSkillsetTab({ onBack, assessor, setAssessor, image }: any) {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthContext();
  // const requestors = [...Array(40)].map((_, index) => ({
  //   id: index + 1,
  //   name: _mock.name.fullName(index),
  // }));
  const navigate = useNavigate();

  const [include, setInclude] = useState<any[]>([]);
  const [exclude, setExclude] = useState<any[]>([]);
  const [skills, setSkills] = useState<any[]>([]);
  const [requestors, setRequestors] = useState<Requestors[] | null>(null);
  // const [selectedValue, setSelectedValue] = useState<any>();

  // const handleRequesterChange = (event: any) => {
  //   console.log("EVENT = " , event);
  //   setSelectedValue(event);
  // };
  const [selectedValue, setSelectedValue] = useState<any>();

  const handleRequesterChange = (event: any) => {
    const selectedRequestId = event.target.value;
    const selectedRequester = requestors?.find((requester) => requester.ID === selectedRequestId);
    console.log('Selected Requester:', selectedRequester);
    setSelectedValue(selectedRequester?.ID);
  };

  useEffect(() => {
    axios
      .get(`/core/getActiveRequestors`)
      .then((response) => {
        setRequestors(response.data as Requestors[]);
      })
      .catch((error) => {
        console.error('Error fetching active Requestors:', error);
      });
  });
  // useEffect(() => {
  //   if (assessor.skills) {
  //     setSkills(assessor.skills);
  //   }
  //   if (assessor.include) {
  //     setInclude(assessor.include);
  //   }
  //   if (assessor.exclude) {
  //     setExclude(assessor.exclude);
  //   }
  // }, [assessor]);
  //----------------------
  useEffect(() => {
    if (assessor.skills) {
      setSkills(assessor.skills);
    }
    if (assessor.include) {
      setInclude(assessor.include);
    }
    if (assessor.exclude) {
      setExclude(assessor.exclude);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setAssessor((prevAssessor: any) => ({
      ...prevAssessor,
      skills,
      include,
      exclude,
    }));
  }, [exclude, include, setAssessor, skills]);
  // ----------------------------------------------
  const handleInclude = (event: any) => {
    if (selectedValue) {
      if (selectedValue !== '') {
        // console.log('Selected Req : ', requestors?.filter((value) => value.ID === 2)[0]?.ReqName);
        if (exclude.indexOf(selectedValue) !== -1) {
          enqueueSnackbar('This Requestor is already part of exclude list', { variant: 'error' });
        } else if (include.indexOf(selectedValue) !== -1) {
          enqueueSnackbar('This Requestor is already part of include list', { variant: 'error' });
        } else {
          setInclude((prevInclude) => [...prevInclude, selectedValue]);
        }
      }
      console.log('Include:', include);
    }
    // setSelectedValue('');
  };

  // const handleExclude = () => {
  //   if (selectedValue) {
  //     setExclude([...exclude, selectedValue]);
  //     console.log('Exclude:', exclude);
  //   }
  //   // setSelectedValue();
  // };
  const handleExclude = (event: any) => {
    if (selectedValue) {
      if (selectedValue !== '') {
        // setExclude((prevInclude) => [...prevInclude, selectedValue]);
        if (include.indexOf(selectedValue) !== -1) {
          enqueueSnackbar('This Requestor is already part of include list', { variant: 'error' });
        } else if (exclude.indexOf(selectedValue) !== -1) {
          enqueueSnackbar('This Requestor is already part of exclude list', { variant: 'error' });
        } else {
          setExclude((prevInclude) => [...prevInclude, selectedValue]);
        }
      }
      console.log('Exclude:', exclude);
    }
    // setSelectedValue('');
  };

  const handleRemoveInclude = (name: string) => {
    setInclude((prevInclude) => prevInclude.filter((item) => item !== name));
  };

  const handleRemoveExclude = (name: string) => {
    setExclude((prevExclude) => prevExclude.filter((item) => item !== name));
  };

  const onFinish = () => {
    setAssessor((prevAssessor: any) => ({
      ...prevAssessor,
      skills,
      include,
      exclude,
    }));
    console.log('Assessor all data = ', assessor);
    const formDataObj = new FormData();
    formDataObj.append('file', image);
    formDataObj.append('userId', user?.ID);
    formDataObj.append('assessor', JSON.stringify(assessor));
    console.log('include array = ', include);
    // include.forEach((obj, index) => {
    //   formDataObj.append(`include[${index}].ID`, obj.ID);
    //   formDataObj.append(`include[${index}].ReqName`, obj.ReqName);
    // });
    // formDataObj.append('include', JSON.stringify(include));
    // formDataObj.append('exclude', JSON.stringify(exclude));
    console.log(formDataObj, assessor);

    axios
      .post('/core/createAssessor', formDataObj)
      .then((response) => {
        console.log('API response:', response.data);
        if (response.data.includes('Success')) {
          enqueueSnackbar('User Created Successfully');
          navigate('/assessorlist');
        } else {
          enqueueSnackbar(response.data, { variant: 'error' });
        }
      })
      .catch((error) => {
        console.error('API error:', error);
        enqueueSnackbar(error, { variant: 'error' });
      });
  };

  function handleClick(event: any) {
    const { value, checked } = event.target;
    if (!checked) {
      setSkills(skills.filter((val) => val !== value));
    } else {
      setSkills([value, ...skills]);
    }
  }

  return (
    <Grid container spacing={2} sx={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
      <Grid container direction="row" spacing={1} md={12} sx={{ margin: 1 }}>
        <Grid item xs={12} md={12}>
          {/* <Card sx={{ py: 3, px: 3, height: '100%' }}> */}

          <Grid item lg={3}>
            <Typography variant="h6">Assessor Skillset</Typography>
          </Grid>
          <Grid container direction="row" spacing={2} lg={12} sx={{ marginTop: 1 }}>
            <Grid item lg={3}>
              <FormControlLabel
                value="Disable AutoAssign"
                control={<Checkbox />}
                label="Disable AutoAssign"
                labelPlacement="end"
                checked={skills.some((skill) => skill === 'Disable AutoAssign')}
                onChange={(event) => handleClick(event)}
              />
            </Grid>

            <Grid item lg={3}>
              <FormControlLabel
                value="Stage 1"
                control={<Checkbox />}
                label="Stage 1"
                labelPlacement="end"
                checked={skills.some((skill) => skill === 'Stage 1')}
                onChange={(event) => handleClick(event)}
              />
            </Grid>

            <Grid item lg={3}>
              <FormControlLabel
                value="Stage 2"
                control={<Checkbox />}
                label="Stage 2"
                labelPlacement="end"
                checked={skills.some((skill) => skill === 'Stage 2')}
                onChange={(event) => handleClick(event)}
              />
            </Grid>

            <Grid item lg={3}>
              <FormControlLabel
              disabled
                value="Stage 3"
                control={<Checkbox />}
                label="Stage 3"
                labelPlacement="end"
                checked={skills.some((skill) => skill === 'Stage 3')}
                onChange={(event) => handleClick(event)}
              />
            </Grid>

            <Grid item lg={3}>
              <FormControlLabel
                value="Audit"
                control={<Checkbox />}
                label="Audit"
                labelPlacement="end"
                checked={skills.some((skill) => skill === 'Audit')}
                onChange={(event) => handleClick(event)}
              />
            </Grid>

            <Grid item lg={3}>
              <FormControlLabel
                value="BX + WOH"
                control={<Checkbox />}
                label="BX + WOH"
                labelPlacement="end"
                checked={skills.some((skill) => skill === 'BX + WOH')}
                onChange={(event) => handleClick(event)}
              />
            </Grid>

            <Grid item lg={3}>
              <FormControlLabel
                value="RPT 1(NatHers)"
                control={<Checkbox />}
                label="RPT 1(NatHers)"
                labelPlacement="end"
                checked={skills.some((skill) => skill === 'RPT 1(NatHers)')}
                onChange={(event) => handleClick(event)}
              />
            </Grid>

            <Grid item lg={3}>
              <FormControlLabel
                value="RPT 2(NatHers,BASIX)"
                control={<Checkbox />}
                label="RPT 2(NatHers,BASIX)"
                labelPlacement="end"
                checked={skills.some((skill) => skill === 'RPT 2(NatHers,BASIX)')}
                onChange={(event) => handleClick(event)}
              />
            </Grid>

            <Grid item lg={3}>
              <FormControlLabel
                value="RPT 3(NatHers,BASIX,BESS)"
                control={<Checkbox />}
                label="RPT 3(NatHers,BASIX,BESS)"
                labelPlacement="end"
                checked={skills.some((skill) => skill === 'RPT 3(NatHers,BASIX,BESS)')}
                onChange={(event) => handleClick(event)}
              />
            </Grid>

            <Grid item lg={3}>
              <FormControlLabel
                value="RPT 4(Everything,New Requestors)"
                control={<Checkbox />}
                label="RPT 4(Everything,New Requestors)"
                labelPlacement="end"
                checked={skills.some((skill) => skill === 'RPT 4(Everything,New Requestors)')}
                onChange={(event) => handleClick(event)}
              />
            </Grid>

            <Grid item lg={3}>
              <FormControlLabel
                value="Skip Audit"
                control={<Checkbox />}
                label="Skip Audit"
                labelPlacement="end"
                checked={skills.some((skill) => skill === 'Skip Audit')}
                onChange={(event) => handleClick(event)}
              />
            </Grid>
          </Grid>

          {/* </Card> */}
        </Grid>
        <Divider sx={{ color: 'black', marginBottom: '10px' }} />

        <Grid item xs={2} md={12}>
          {/* <Card sx={{ py: 3, px: 3, height: '100%' }}> */}
          <div>
            {/* <Select onChange={handleRequesterChange} sx={{ width: '300px' }}>
                {requestors.map((requester) => (
                  <MenuItem key={requester.id} value={requester.name}>
                  {requester.name}
                  </MenuItem>
                  ))}
                </Select> */}
            <Grid item lg={3} sx={{ marginBottom: 2 }}>
              <Typography variant="h6">Include/Exclude Requestor</Typography>
            </Grid>
            <Select
              onChange={handleRequesterChange}
              sx={{ width: '300px' }}
              placeholder="Select Requestor"
            >
              {requestors?.map((requester) => (
                <MenuItem key={requester?.ID} value={requester?.ID}>
                  {requester.ReqName} : {requester.ReqSubloc}
                </MenuItem>
              ))}
            </Select>
            <Button
              variant="contained"
              onClick={handleInclude}
              sx={{ marginLeft: '30px', borderRadius: '40px' }}
            >
              Include
            </Button>
            <Button
              variant="contained"
              onClick={handleExclude}
              sx={{ marginLeft: '30px', borderRadius: '40px' }}
            >
              Exclude
            </Button>

            <Grid container direction="row" spacing={3} md={12} sx={{ margin: 1 }}>
              <Grid item>
                <Box mt={2} sx={{ maxHeight: '200px', overflow: 'auto' }}>
                  <Typography variant="h6"> Included:</Typography>
                  {include.map((item) => (
                    <Box
                      key={item}
                      sx={{ background: 'antiquewhite', width: '550px', borderRadius: '40px' }}
                    >
                      <p key={item} style={{ marginLeft: '20px', color: 'black' }}>
                        {/* {requestors &&
                          requestors.filter((requestor) => requestor.ID === item)[0].ReqName} */}
                        {requestors &&
                          requestors
                            .filter((requestor) => requestor.ID === item)
                            .map(
                              (filteredRequestor) =>
                                `${filteredRequestor.ReqName} : ${filteredRequestor.ReqSubloc}`
                            )
                            .join(', ')}
                        <IconButton onClick={() => handleRemoveInclude(item)}>
                          <CloseIcon />
                        </IconButton>
                      </p>
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid item md={1} />
              <Grid item>
                <Box mt={2} sx={{ maxHeight: '200px', overflow: 'auto' }}>
                  <Typography variant="h6"> Excluded:</Typography>
                  {exclude.map((item) => (
                    <Box
                      key={item}
                      sx={{ background: 'antiquewhite', width: '550px', borderRadius: '40px' }}
                    >
                      <p key={item} style={{ marginLeft: '20px', color: 'black' }}>
                        {/* {requestors &&
                          requestors.filter((requestor) => requestor.ID === item)[0].ReqName} */}
                        {requestors &&
                          requestors
                            .filter((requestor) => requestor.ID === item)
                            .map(
                              (filteredRequestor) =>
                                `${filteredRequestor.ReqName} : ${filteredRequestor.ReqSubloc}`
                            )
                            .join(', ')}
                        <IconButton onClick={() => handleRemoveExclude(item)}>
                          <CloseIcon />
                        </IconButton>
                      </p>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </div>
          {/* </Card> */}
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" sx={{ marginTop: '20px' }}>
        <Grid item>
          <Button variant="outlined" color="inherit" onClick={() => onBack()}>
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" type="submit" color="primary" onClick={() => onFinish()}>
            Finish
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
