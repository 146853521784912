import {
  Button,
  // Card,
  // CardContent,
  // CardHeader,
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  // Container,
  // Dialog,
  // DialogTitle,
  // Avatar,
  FormControlLabel,
  // FormLabel,
  Grid,
  // InputLabel,
  // LinearProgress,
  // Link,
  // ListItem,
  MenuItem,
  // Radio,
  // RadioGroup,
  Select,
  // SelectChangeEvent,
  // Switch,
  // TextField,
  // alpha,
  // useTheme,
  // Stack,
  Typography,
  IconButton,
  // Tabs,
  // Tab,
  // FormControl,
  // FormGroup,
  Checkbox,
  Divider,
  Switch,
} from '@mui/material';
import {
  Box,
  //  styled, width
} from '@mui/system';
// import TextareaAutosize from '@mui/base/TextareaAutosize';
// import {
//   DateTimePicker,
//   DesktopDatePicker,
//   LocalizationProvider,
//   StaticDatePicker,
//   TimePicker,
// } from '@mui/x-date-pickers';
import {
  // SetStateAction, useCallback,
  useEffect,
  useState,
} from 'react';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import Iconify from 'src/components/iconify';
// import Label from 'src/components/label/Label';
import { useSnackbar } from 'src/components/snackbar';
import { useAuthContext } from 'src/auth/useAuthContext';
// import Editor from 'src/components/editor';
// import _mock from 'src/_mock/_mock';
// import { m, AnimatePresence } from 'framer-motion';
// import * as Yup from 'yup';
// form
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import Upload from 'src/components/upload/Upload';
// import { TreeItem, TreeItemProps, TreeView, LoadingButton } from "@mui/lab";
// import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
// import { fDate } from 'src/utils/formatTime';
// import FileThumbnail from 'src/components/file-thumbnail';
// import { varFade } from 'src/components/animate';
// import dayjs from 'dayjs';
// import { DatePicker } from '@mui/lab';
// import TextMaxLine from 'src/components/text-max-line';
// import { TextFieldProps } from '@material-ui/core';
import axios from 'src/utils/axios';
import { CloseIcon } from 'src/theme/overrides/CustomIcons';
import { useNavigate } from 'react-router';

// interface Schedul {
//   day: string;
//   fromTime: string;
//   toTime: string;
// }

interface SkillsetMaster {
  SkillsetID: number;
  SkillsetName: string;
  SkillsetCode: string;
  CreatedBy: number;
  CreatedAt: string;
}

interface UserSkillset {
  UserID: number;
  SkillsetID: number;
  SkillsetFlag: number;
  CreatedBy?: number;
  CreatedAt?: string;
}

type IncExc = {
  user_id: number;
  requestor_id: number;
  include_exclude_flag: string;
  created_by: number;
  created_at: string;
};

type Requestors = {
  ID: number;
  ReqName: string;
  JobRequestorID: string;
  ReqSubloc: string;
  ReqCreatedDatetime: string;
  ReqEmailDomain: string;
  ReqContactPhone: string;
  ReqStatus: string;
  IsDefaultFinal: string;
  IsPrelimFinalMentionedSubject: string;
  ReqType: string;
  ReqJobTypes: string;
  ReqEmailResponse: string;
  PeopleListed: string;
  Notes: string;
};

export default function SkillsetTab({ id, edit }: any) {
  const { enqueueSnackbar } = useSnackbar();
  const [skillsetMaster, setSkillsetMaster] = useState<SkillsetMaster[]>([]);
  const [skillset, setSkillset] = useState<UserSkillset[]>([]);
  // const [incExc, setincExc] = useState<IncExc[]>([]);
  const [requestors, setRequestors] = useState<Requestors[] | null>(null);
  const { user } = useAuthContext();
  const [isEnabled, setIsEnabled] = useState(false);
  if (user?.UserRole === 'Assessor') {
    edit = false;
  }
  useEffect(() => {
    axios
      .get(`/core/getActiveRequestors`)
      .then((response) => {
        setRequestors(response.data as Requestors[]);
        console.log(
          'Requestor data = ',
          response.data?.filter((value: any) => value.ID === 3)[0].ReqName
        );
      })
      .catch((error) => {
        console.error('Error fetching active Requestors:', error);
      });
    axios
      .get(`/core/getSkillsetMaster`)
      .then((response) => {
        setSkillsetMaster(response.data as SkillsetMaster[]);
      })
      .catch((error) => {
        enqueueSnackbar('Error Fetching Skillset', { variant: 'error' });
        console.error('Error fetching Skillset:', error);
      });

    axios
      .get(`/core/getAssessorSkillset?Id=${id}`)
      .then((response) => {
        setSkillset(response.data as UserSkillset[]);
      })
      .catch((error) => {
        enqueueSnackbar('Error Fetching Skillset', { variant: 'error' });
        console.error('Error fetching Skillset:', error);
      });
    axios
      .get(`/core/getIncExcForAssessor?id=${id}`)
      .then((response) => {
        // setincExc(response.data as IncExc[]);
        const incexc = response.data as IncExc[];
        setInclude(
          incexc
            .filter((include: any) => include.include_exclude_flag === 'include')
            .map((inc) => inc.requestor_id)
        );
        setExclude(
          incexc
            .filter((include: any) => include.include_exclude_flag === 'exclude')
            .map((inc) => inc.requestor_id)
        );
      })
      .catch((error) => {
        console.error('Error fetching assessor include and exclude:', error);
      });
  }, [enqueueSnackbar, id]);

  const navigate = useNavigate();

  const [include, setInclude] = useState<any[]>([]);
  const [exclude, setExclude] = useState<any[]>([]);
  // const [skills, setSkills] = useState<any[]>([]);

  const [selectedValue, setSelectedValue] = useState('');

  const handleRequesterChange = (event: any) => {
    setSelectedValue(event.target.value);
  };
  function capitalizeAssessorSkillsetName(str: string) {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  const handleInclude = (event: any) => {
    if (selectedValue) {
      if (selectedValue !== '') {
        // setInclude((prevInclude) => [...prevInclude, selectedValue]);
        if (exclude.indexOf(selectedValue) !== -1) {
          enqueueSnackbar('This Requestor is already part of exclude list', { variant: 'error' });
        } else if (include.indexOf(selectedValue) !== -1) {
          enqueueSnackbar('This Requestor is already part of include list', { variant: 'error' });
        } else {
          setInclude((prevInclude) => [...prevInclude, selectedValue]);
        }
      }
      console.log('Include:', include);
    }
    // setSelectedValue('');
  };

  const handleExclude = () => {
    if (selectedValue) {
      // setExclude([...exclude, selectedValue]);
      if (selectedValue !== '') {
        if (include.indexOf(selectedValue) !== -1) {
          enqueueSnackbar('This Requestor is already part of include list', { variant: 'error' });
        } else if (exclude.indexOf(selectedValue) !== -1) {
          enqueueSnackbar('This Requestor is already part of exclude list', { variant: 'error' });
        } else {
          setExclude([...exclude, selectedValue]);
        }
      }
      console.log('Exclude:', exclude);
    }
    // setSelectedValue('');
  };

  const handleRemoveInclude = (name: string) => {
    setInclude((prevInclude) => prevInclude.filter((item) => item !== name));
  };

  const updateSkillset = () => {
    const skillse = {
      skillsets: skillset.map((item) => item.SkillsetID),
      include,
      exclude,
    };
    axios
      .post(`/core/updateAssessorSkillset?Id=${id}&userid=${user?.ID}`, skillse)
      .then((response) => {
        enqueueSnackbar(response.data, { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar('Error Updating Skillset', { variant: 'error' });
      });
  };
  const handleRemoveExclude = (name: string) => {
    setExclude((prevExclude) => prevExclude.filter((item) => item !== name));
  };

  function handleClick(event: any) {
    const { value, checked } = event.target;
    if (!checked) {
      setSkillset(
        skillset.filter(
          (val) =>
            !skillsetMaster.some(
              (valu) => valu.SkillsetName === value && valu.SkillsetID === val.SkillsetID
            )
        )
      );
    } else {
      const ski = skillsetMaster.find((skillse) => skillse.SkillsetName === value);
      if (ski) {
        setSkillset([{ SkillsetFlag: 1, SkillsetID: ski.SkillsetID, UserID: id }, ...skillset]);
      }
    }
  }

  return (
    <>
      <Grid
        container
        display="flow"
        sx={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}
      >
        <Grid container direction="row" spacing={1} md={12} sx={{ margin: 1 }}>
          <Grid item xs={12} md={12}>
            {/* <Card sx={{ py: 3, px: 3, height: '100%' }}> */}

            <Grid item lg={3}>
              <Typography variant="h6">Assessor Skillset</Typography>
            </Grid>
            <Grid container direction="row" spacing={2} lg={12} sx={{ marginTop: 1 }}>
              {skillsetMaster?.map((skillName: SkillsetMaster) => (
                <Grid item key={skillName.SkillsetID} lg={3}>
                  <FormControlLabel
                    disabled={!edit || skillName.SkillsetName === 'Stage 3'}
                    value={skillName.SkillsetName}
                    control={<Checkbox />}
                    label={capitalizeAssessorSkillsetName(skillName.SkillsetName)}
                    labelPlacement="end"
                    // disabled={!edit}
                    checked={skillset?.some((skill) => skill.SkillsetID === skillName.SkillsetID)}
                    onChange={(event) => handleClick(event)}
                  />
                </Grid>
              ))}
            </Grid>

            {/* </Card> */}
          </Grid>
          <Divider sx={{ color: 'black', marginBottom: '10px' }} />
          {edit && (
            <Grid item xs={2} md={12}>
              {/* <Card sx={{ py: 3, px: 3, height: '100%' }}> */}
              <div>
                {/* <Select onChange={handleRequesterChange} sx={{ width: '300px' }}>
               {requestors.map((requester) => (
                 <MenuItem key={requester.id} value={requester.name}>
                 {requester.name}
                 </MenuItem>
                 ))}
               </Select> */}
                <Grid item lg={3} sx={{ marginBottom: 2 }}>
                  <Typography variant="h6">Include/Exclude Requestor</Typography>
                </Grid>
                <Select
                  onChange={handleRequesterChange}
                  sx={{ width: '300px' }}
                  placeholder="Select Requestor"
                >
                  {requestors &&
                    requestors.map((requester) => (
                      <MenuItem key={requester.ID} value={requester.ID}>
                        {requester.ReqName} : {requester.ReqSubloc}
                      </MenuItem>
                    ))}
                </Select>
                <Button
                  variant="contained"
                  onClick={handleInclude}
                  sx={{ marginLeft: '30px', borderRadius: '40px' }}
                >
                  Include
                </Button>
                <Button
                  variant="contained"
                  onClick={handleExclude}
                  sx={{ marginLeft: '30px', borderRadius: '40px' }}
                >
                  Exclude
                </Button>
              </div>
              {/* </Card> */}
            </Grid>
          )}

          <Grid container direction="row" spacing={3} md={12} sx={{ margin: 1 }}>
            <Grid item>
              <Box mt={2} sx={{ maxHeight: '200px', overflow: 'auto' }}>
                <Typography variant="h6"> Included:</Typography>
                {include.map((item) => (
                  <Box
                    key={item}
                    sx={{ background: 'antiquewhite', width: '550px', borderRadius: '40px' }}
                  >
                    <p key={item} style={{ marginLeft: '20px', color: 'black' }}>
                      {/* {requestors && requestors.filter(requestor => requestor.ID === item)[0].ReqName} */}
                      {requestors &&
                        requestors
                          .filter((requestor) => requestor.ID === item)
                          .map(
                            (filteredRequestor) =>
                              `${filteredRequestor.ReqName} : ${filteredRequestor.ReqSubloc}`
                          )
                          .join(', ')}

                      {edit === true && (
                        <IconButton onClick={() => handleRemoveInclude(item)}>
                          <CloseIcon />
                        </IconButton>
                      )}
                    </p>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item md={1} />
            <Grid item>
              <Box mt={2} sx={{ maxHeight: '200px', overflow: 'auto' }}>
                <Typography variant="h6"> Excluded:</Typography>
                {exclude.map((item) => (
                  <Box
                    key={item}
                    sx={{ background: 'antiquewhite', width: '550px', borderRadius: '40px' }}
                  >
                    <p key={item} style={{ marginLeft: '20px', color: 'black' }}>
                      {/* {requestors &&
                        requestors.filter((requestor) => requestor.ID === item)[0].ReqName} */}
                      {requestors &&
                        requestors
                          .filter((requestor) => requestor.ID === item)
                          .map(
                            (filteredRequestor) =>
                              `${filteredRequestor.ReqName} : ${filteredRequestor.ReqSubloc}`
                          )
                          .join(', ')}
                      {edit === true && (
                        <IconButton onClick={() => handleRemoveExclude(item)}>
                          <CloseIcon />
                        </IconButton>
                      )}
                    </p>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" sx={{ marginTop: '20px' }}>
            <Grid item>&nbsp;</Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => {
                  navigate('/assessorlist');
                }}
                sx={{ mr: 1 }}
                color="primary"
              >
                Back
              </Button>
              {edit && (
                <Button variant="contained" onClick={updateSkillset} color="primary">
                  Update
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* </Container> */}
    </>
  );
}
