import { useEffect, useState } from 'react';
import axios from 'src/utils/axios';
import { useSnackbar } from 'src/components/snackbar';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';

// @mui
import {
  Stack,
  Dialog,
  Button,
  // TextField,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  // RadioGroup,
  // FormControlLabel,
  // Radio,
  Typography,
  styled,
  Checkbox,
} from '@mui/material';
import { useAuthContext } from 'src/auth/useAuthContext';
// import label from 'src/components/label';
import FileThumbnail from 'src/components/file-thumbnail';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFRadioGroup, RHFTextField } from 'src/components/hook-form';
// components
import Editor from 'src/components/editor';
import { 
  // useLocation, 
  useNavigate 
} from 'react-router';
// import Iconify from '../../components/iconify';
// import { Upload } from '../../components/upload';

// ----------------------------------------------------------------------

interface Props extends DialogProps {
  open: boolean;
  onClose: VoidFunction;
  getComments: VoidFunction;
  getEmails: VoidFunction;
  job: any;
  attachments: any;
  initial_email_content: any;
}

type FormValuesProps = {
  EmailSubject: string;
  SendEmail: string;
  AddressTo: string;
  CCAddress: string;
};

interface EmailInfo {
  EmailRecordID: number;
  EmailMsgID: number;
  EmailFolderID: number;
  EmailSubject: string;
  EmailSummary: string;
  EmailHasAttach: number;
  EmailFlagID: string;
  EmailSender: string;
  EmailToAddress: string;
  EmailCCAddress: string;
  EmailLabelID: number;
  EmailReceivedTime: number;
  EmailStatus: number;
  EmailStatus2: number;
  ThreadCount?: number | null;
  ThreadID?: number | null;
  EmailIsJob: string;
  EmailCreatedAt: string;
  EmailIsProcessed: string;
  EmailProcessedAt: string;
  EmailJSON: any;
}

export default function JobCompleteDialog({
  open,
  onClose,
  getComments,
  getEmails,
  job,
  attachments,
  initial_email_content,
}: Props) {
  const { user } = useAuthContext();
  // const [selectedDocumentsForConfirmation, setSelectedDocumentsForConfirmation] = useState<string[]>([]);
  const [confirmationContent, setConfirmationContent] = useState<React.ReactNode>(null);
  // const [data, setData] = useState<EmailInfo | null>(null);
  const [data, setData] = useState(false);
  const [formData, setFormData] = useState(new FormData());
  const [editor, setEditor] = useState('');
  const [parkJobComment, setParkJobCommet] = useState('');
  const navigate = useNavigate();
  //  console.log("Latest email :", initial_email_content[initial_email_content.length - 1]?.EmailSummary)
  const latest_email = initial_email_content[initial_email_content.length - 1]?.EmailSummary;
  const onValueChanges = (value: any) => {
    setEditor(value);
  };

  const [files, setFiles] = useState<(File | string)[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  //    console.log("Attachments : ", attachments)
  useEffect(() => {
    if (!open) {
      setFiles([]);
    }
  }, [open]);

  const CommentSchema = Yup.object().shape({
    EmailSubject: Yup.string().required('Email Subject is required'),
    SendEmail: Yup.boolean().required('Send Email Option is required'),
    AddressTo: Yup.string().required('To Address is required'),
    CCAddress: Yup.string(),
  });
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(CommentSchema),
    defaultValues: {
      EmailSubject: '',
      SendEmail: 'true',
      AddressTo: '',
      CCAddress: '',
    },
  });

  const {
    // reset,
    handleSubmit,
    setValue,
    watch,
    // formState: { isSubmitting },
  } = methods;
  const values = watch();
  useEffect(() => {
    methods.setValue('EmailSubject', job?.JobEmailSubject);
  }, [job, methods]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log('Email Record id ', job?.EmailRecordID, ' for job :', job?.JobRecordID);
    if (job?.EmailRecordID) {
      axios
        .get(`/core/getEmailInfoById?Id=${job?.EmailRecordID}`)
        .then((response) => {
          console.log('getEmailInfoById response = ', response.data);
          setData(true);
          //        setData(response.data as EmailInfo)
          setValue('AddressTo', `${response.data.EmailToAddress} , ${response.data.EmailSender}`);
          if (response.data.EmailToAddress === '' && response.data.EmailSender === '') {
            console.log('Both To address and Email Sender is empty');
            setValue('AddressTo', ``);
          } else if (response.data.EmailToAddress === '') {
            setValue('AddressTo', `${response.data.EmailSender}`);
          } else {
            setValue('AddressTo', `${response.data.EmailToAddress} , ${response.data.EmailSender}`);
          }
          setValue('CCAddress', response.data.EmailCCAddress);
          setEditor(latest_email);
        })
        .catch((error) => {
          console.error('Error fetching getEmailInfoById:', error);
        });
    }

    console.log(data);
  }, [job?.EmailRecordID, job?.JobRecordID, setValue, latest_email, data]);

  // const handleDrop = useCallback(
  //   (acceptedFiles: File[]) => {
  //     const newFiles = acceptedFiles.map((file) =>
  //       Object.assign(file, {
  //         preview: URL.createObjectURL(file),
  //       })
  //     );

  //     setFiles([...files, ...newFiles]);
  //   },
  //   [files]
  // );

  function formatFileSize(bytes: any) {
    if (bytes === 0) return '0 Bytes';

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / 1024 ** i).toFixed(2))} ${sizes[i]}`;
  }

  const StyledRoot = styled('div')(({ theme }) => ({
    position: 'relative',
    padding: theme.spacing(0, 2),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.neutral,
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
    '&:hover': {
      zIndex: 9,
      position: 'relative',
      // boxShadow: theme.customShadows.z24,
      '& #mailActions': { opacity: 1 },
    },
  }));

  // const handleConfirm = async () => {
  //   try {
  //     console.log('In confirm');
  //     setIsLoading(true);
  //     console.log('confirm handleConfirm:', isLoading);
  //     setConfirmationContent(null)
  //   } catch (error) {
  //     // setTimeout(() => {
  //     //   setIsLoading(false);
  //     // }, 1000);
  //     enqueueSnackbar(error, { variant: 'error' });
  //     console.error('Error uploading files:', error);
  //   }
  // };

  const handleConfirm = async () => {
    try {
      console.log('In confirm');
      setConfirmationContent(null);
      console.log('Form data = ', formData);
      if (editor !== '') {
        setIsLoading(true);
        axios
          .post(`/core/completeJob`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            console.log('JobComplete Response', response);
            enqueueSnackbar(response.data);
            setEditor('');
            // setValue('AddressTo', '');
            // setValue('CCAddress', '');
            getEmails();
            // onClose()
            closeFunction();
            navigate('/jobs');
          })
          .catch((error) => {
            setIsLoading(false);
            console.log('JobComplete error', error);
            enqueueSnackbar(error.error, { variant: 'error' });
          });
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      console.error('Error uploading files:', error);
    }
  };

  const handleConfirmCancel = async () => {
    try {
      setFiles([]);
      setConfirmationContent(null);
    } catch (error) {
      setTimeout(() => {}, 1000);
      enqueueSnackbar(error, { variant: 'error' });
      console.error('Error uploading files:', error);
    }
  };

  const onSubmit = async (dat: FormValuesProps) => {
    try {
      console.log('Inside onSubmit', dat);
      // Remove all entries
      formData.forEach((value, key) => {
        formData.delete(key);
      });
      setFormData(new FormData());

      if (values.SendEmail === 'true') {
        await CommentSchema.validate(dat, { abortEarly: false });
      }

      if (editor === '') {
        enqueueSnackbar('Email Content is Required', { variant: 'error' });
        // setIsLoading(false)
      }

      if (editor !== '') {
        handleSendConfirmation();
      }

      // const formData = new FormData();

      // Remove all entries
      const newFormData = new FormData();
      const trimmedEditor = editor?.trim();
      const updatedEditor = trimmedEditor
        ?.replace(/<p\b([^>]*)>/gi, '<div$1>')
        .replace(/<\/p>/gi, '</div>');

      if (values.SendEmail === 'true') {
        formData.append('AttachmentId', JSON.stringify(selectedAttachments));
        formData.append(`EmailSubject`, dat.EmailSubject);
        formData.append(`EmailContent`, updatedEditor);
        formData.append(`AddressTo`, dat.AddressTo);
        formData.append(`CCAddress`, dat.CCAddress);
      }
      formData.append(`JobRecordID`, job.JobRecordID);
      formData.append(`SendEmail`, String(dat.SendEmail));
      formData.append(`JobEmailID`, job.JobEmailMsgID);
      formData.append(`EntryBy`, user?.ID);
      console.log('Form data in onsubmit :', formData);
      //      console.log("newFormData :" , newFormData)
      //      setFormData(newFormData);
    } catch (error) {
      console.error(error);
      // setIsLoading(false)
    }
  };

  const postComment = (comment: string) => {
    console.log('In Post', comment);

    const payload = {
      Comment: comment,
      JobRecordID: job.JobRecordID,
      Sequence: comment?.length,
    };
    if (comment !== '') {
      axios
        .post(`/core/postNewComment?Id=${user?.ID}`, payload)
        .then((_response) => {
          // if (comment) {
          //   comment = '';
          // }
          setParkJobCommet('');
          getComments();
          onClose();
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: 'error' });
        });
    } else {
      enqueueSnackbar('Enter Something To Post', { variant: 'warning' });
    }
  };

  const markAsComplete = () => {
    try {
      console.log('In', parkJobComment);

      const formData1 = new FormData();
      // setIsLoading(true)
      formData1.append(`JobRecordID`, job.JobRecordID);
      formData1.append(`SendEmail`, values.SendEmail);
      formData1.append(`JobEmailID`, job.JobEmailMsgID);
      formData1.append(`EntryBy`, user?.ID);

      console.log(formData1);

      if (parkJobComment !== '') {
        axios
          .post(`/core/completeJob`, formData1, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            enqueueSnackbar(response.data);
            postComment(parkJobComment);
            setEditor('');
            setValue('AddressTo', '');
            setValue('CCAddress', '');
            onClose();
            getComments();
            getEmails();
            navigate('/jobs');
            // setIsLoading(true)
          })
          .catch((error) => {
            enqueueSnackbar(error);
            // setIsLoading(true)
          });
      } else {
        enqueueSnackbar('Please Enter Comment', { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
      // setIsLoading(true)
    }
  };

  const closeFunction = () => {
    try {
      setSelectedAttachments([]);
      setIsLoading(false);
      setData(false);
      onClose();
    } catch (error) {
      console.error(error);
      // setIsLoading(true)
    }
    console.log(data);
  };

  // const handleRemoveFile = (inputFile: File | string) => {
  //   const filtered = files.filter((file) => file !== inputFile);
  //   setFiles(filtered);
  // };

  // const handleRemoveAllFiles = () => {
  //   setFiles([]);
  // };

  const [selectedAttachments, setSelectedAttachments] = useState<any>([]);

  const handleCheckboxChange = (jobAttachID: any) => {
    const isSelected = selectedAttachments.includes(jobAttachID);
    //    console.log(selectedAttachments)
    if (isSelected) {
      setSelectedAttachments((prevSelected: any) =>
        prevSelected.filter((id: any) => id !== jobAttachID)
      );
    } else {
      setSelectedAttachments((prevSelected: any) => [...prevSelected, jobAttachID]);
    }
  };

  const handleSendConfirmation = async () => {
    console.log('selectedAttachments :', selectedAttachments);
    if (selectedAttachments.length > 0) {
      const allowedExtensions = ['.xlsx', '.csv', '.frate', '.xls', '.xlrd'];
      const selectedFileNames = attachments
        .filter((item: any) => selectedAttachments.includes(item.JobAttachLoc))
        //     .filter((item: any) => selectedAttachments.includes(item.JobAttachLoc))
        .map((item: any) => item.JobAttachName)
        .join('<br />');
      const IncorrectFilenames = attachments
        .filter((item: any) => selectedAttachments.includes(item.JobAttachLoc))
        .filter((item: any) => {
          const fileExtension = item.JobAttachLoc.slice(item.JobAttachLoc.lastIndexOf('.'));
          return allowedExtensions.includes(fileExtension);
        })
        //     .filter((item: any) => selectedAttachments.includes(item.JobAttachLoc))
        .map((item: any) => item.JobAttachName)
        .join('<br />');
      if (selectedFileNames) {
        let confirmationMessage = `Kindly confirm to Proceed ?`;
        if (IncorrectFilenames) {
          confirmationMessage = `You are sending Excel files or Frate Files to Requestor. Kindly confirm? <br> ${IncorrectFilenames}`;
        }
        setConfirmationContent(
          <Dialog open onClose={() => setConfirmationContent(null)}>
            <DialogTitle>Confirmation</DialogTitle>
            {/* <DialogContent>{confirmationMessage}</DialogContent> */}
            <DialogContent>
              <div dangerouslySetInnerHTML={{ __html: confirmationMessage }} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm} color="primary">
                Confirm
              </Button>
              <Button onClick={handleConfirmCancel} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        );
      } else {
        enqueueSnackbar('Selected files not found.', { variant: 'error' });
      }
    } else {
      enqueueSnackbar('Please select files to send.', { variant: 'error' });
    }
  };

  //  setEditor(latest_email);

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}> Mark as Complete </DialogTitle>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
          <Stack spacing={2}>
            {/* <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel> */}
            <RHFRadioGroup
              row
              name="SendEmail"
              label=""
              spacing={4}
              options={[
                {
                  value: true,
                  label: 'Mark as Complete (Send Job back to the client) - with Send Email',
                },
                { value: false, label: 'Park job for rechecking' },
              ]}
              disabled={false}
            />
            {values.SendEmail === 'true' && (
              <>
                <RHFTextField name="AddressTo" label="Address To" sx={{ width: '100%' }} />
                <RHFTextField name="CCAddress" label="CC Address" sx={{ width: '100%' }} />
                <RHFTextField name="EmailSubject" label="Email Subject" sx={{ width: '100%' }} />
                <Editor value={editor} onChange={onValueChanges} onchan={setEditor} />

                <Typography variant="subtitle2" noWrap>
                  Select attachments to Send Email to Requestor/Builder Note : Minimum 1 attachment
                  to be selected
                </Typography>
                {attachments &&
                  attachments.map((list: any, key: any) => (
                    <StyledRoot sx={{ bgcolor: 'background.paper' }}>
                      <Checkbox
                        checked={selectedAttachments.includes(list.JobAttachLoc)}
                        onChange={() => handleCheckboxChange(list.JobAttachLoc)}
                      />
                      <Stack
                        key={key}
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        sx={{
                          my: 1,
                          px: 1,
                          py: 0.75,
                        }}
                      >
                        <FileThumbnail file={list.JobAttachExtension} />

                        <Stack flexGrow={1} sx={{ minWidth: 0 }}>
                          <Typography variant="subtitle2" noWrap>
                            {list.JobAttachName}
                          </Typography>
                          <Typography variant="subtitle2" noWrap>
                            {formatFileSize(list.JobAttachSize)}
                          </Typography>
                        </Stack>
                      </Stack>
                    </StyledRoot>
                  ))}
              </>
            )}

            {values.SendEmail === 'false' && (
              <RHFTextField
                name="ParkJobComment"
                label="Write Comment"
                onChange={(event) => setParkJobCommet(event.target.value)}
                multiline
                rows={4}
                sx={{ width: '100%' }}
              />
            )}
          </Stack>
        </DialogContent>

        <DialogActions>
          {values.SendEmail === 'true' && (
            <LoadingButton
              variant="contained"
              loading={isLoading}
              type="submit"
              disabled={selectedAttachments.length < 1}
            >
              Send Mail
            </LoadingButton>
          )}
          {values.SendEmail !== 'true' && (
            <LoadingButton variant="contained" loading={isLoading} onClick={markAsComplete}>
              Save
            </LoadingButton>
          )}
          <Button
            variant="outlined"
            color="secondary"
            // onClick={onClose}
            onClick={closeFunction}
            disabled={isLoading}
          >
            Cancel
          </Button>

          {user?.UserRole !== 'Assessor' && confirmationContent}
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
