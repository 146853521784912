import {
  Button,
  // Checkbox,
  Container,
  // FormControlLabel,
  // FormGroup,
  // Grid,
  // InputAdornment,
  // TextField,
  // Typography,
} from '@mui/material';
import axios from 'src/utils/axios';
import {
  DataGrid,
  GridColDef,
  // GridRowSelectionModel,
  // GridToolbar,
  // GridCsvExportOptions,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
} from '@mui/x-data-grid';

// import { id } from 'date-fns/locale';
import { useEffect, useState } from 'react';

import { 
  // useLocation,
   useNavigate } from 'react-router-dom';
// import Iconify from 'src/components/iconify';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useSettingsContext } from 'src/components/settings';

export default function AssessorWorkload() {
  // const location = useLocation();
  // const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState<any[]>([]);
  // const [selectedRows, setSelectedRows] = useState<any[]>([]);
  // const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAssessorsWorkload();
    setTimeout(() => {
      console.log('Filtered = ', filteredData);
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchAssessorsWorkload() {
    try {
      const response = await axios.get('/core/getAllAssessorWorkload');
      console.log('assessor workload:', response.data);

      const newArray = response.data.map(({ UserID, ...obj }: any) => ({
        id: UserID,
        ...obj,
      }));
      console.log('New array', newArray);
      setFilteredData(newArray);
    } catch (error) {
      console.error(error);
    }
  }

  const goToAssessoerList = () => {
    navigate('/assessorlist');
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'UserFname',
      headerName: 'First Name',
      // align: 'center',
      // headerAlign: 'center',
      width: 100,
      sortable: false,

      disableColumnMenu: true,
    },
    {
      field: 'UserLname',
      headerName: 'Last Name',
      // align: 'center',
      // headerAlign: 'center',
      width: 100,
      sortable: false,

      disableColumnMenu: true,
    },
    {
      field: 'SingleStoreyJobs',
      headerName: 'Single',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      sortable: false,

      disableColumnMenu: true,
    },

    {
      field: 'DoubleStoreyJobs',
      headerName: 'Double',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      sortable: false,

      disableColumnMenu: true,
    },
    {
      field: 'ADiyJobs',
      headerName: 'ADIYJobs',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      sortable: false,

      disableColumnMenu: true,
    },
    {
      field: 'RAJobs',
      headerName: 'RA jobs',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      sortable: false,

      disableColumnMenu: true,
    },
    {
      field: 'FinalsJobs',
      headerName: 'Finals',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      sortable: false,

      disableColumnMenu: true,
    },
    {
      field: 'QueryJobs',
      headerName: 'Query ',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'SimulationJobs',
      headerName: 'Simulation ',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },

    {
      field: 'OnHoldJobs',
      headerName: 'On Hold',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'UrgentJobs',
      headerName: 'Urgent',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'ArcoJobs',
      headerName: 'ARCO',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'TotalJobs',
      headerName: 'Total',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
  ];
  const { themeStretch } = useSettingsContext();

  return (
    <Container sx={{ maxWidth: '100% !important', paddingTop: '50px', paddingBottom: '50px' }}>
      <Helmet>
        <title> EA Assessor Workload</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Assessor Workload"
          links={[
            {
              name: 'Assessor',
              href: '/assessorlist',
            },
            { name: 'Assessor Workload' },
          ]}
        />
        <DataGrid
          disableRowSelectionOnClick
          rows={filteredData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
          }}
          // pagination
          sx={{ height: '700px' }}
          onRowSelectionModelChange={(newSelectionModel: any) => {
            // setSelectionModel(newSelectionModel);
          }}
          slots={{
            toolbar: MyExportButton,
          }}
        />
        <Button
          variant="soft"
          color="secondary"
          onClick={goToAssessoerList}
          sx={{ float: 'right', marginTop: '20px' }}
        >
          Back
        </Button>
        {/* <Button
        variant="contained"
        color="primary"
        onClick={handleUpdateClick}
        sx={{ float: 'right', marginTop: '20px', marginRight: '20px' }}
      >
        Update
      </Button> */}
      </Container>
    </Container>
  );
}

function MyExportButton() {
  const currentDate = new Date();
  const formattedDateTime = currentDate.toLocaleDateString().replace(/\//g, '');
  const file_Name = `EA_Assessor_Workload_${formattedDateTime}`;

  return (
    // <GridToolbarContainer>
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem
          options={{
            // fileName: 'EA_Requestor_Data',
            fileName: file_Name,
            // delimiter: ';',
            utf8WithBom: true,
          }}
        />
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}
